import { GET_SERVICES, SERVICES_LOADING, DELETE_SERVICE } from '../actions/types';
 
const initialState = {
  servicesData: [], 
  loading: false
}

//services has to be an object because it contains the services list and the count

export default function(state = initialState, action){
  switch(action.type){
    case GET_SERVICES:
      return {
        ...state,
        servicesData: action.payload,
        loading: false
      };
      case DELETE_SERVICE:
       return {
         ...state,
         servicesData: action.payload
       };
      case SERVICES_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
    return state;
  }
}