import { 
  SET_TOGGLE_L_DRAWER,
  SET_TOGGLE_R_DRAWER 
} from './types';


//OPEN THE LEFT DRAWER


export const toggleDrawerL = (open) => {

  if(open === true){
    return {
      type: SET_TOGGLE_L_DRAWER,
      payload: {
        open: false
      }
    }
  }else{
    return {
      type: SET_TOGGLE_L_DRAWER,
      payload: {
        open: true
      }
    }
  }

  
};

export const toggleDrawerR = (open) => {

  if(open === true){
    return {
      type: SET_TOGGLE_R_DRAWER,
      payload: {
        open: false
      }
    }
  }else{
    return {
      type: SET_TOGGLE_R_DRAWER,
      payload: {
        open: true
      }
    }
  }

  
};