import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import 'foundation-sites';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions'

//## REDUX SETUP
import { Provider } from 'react-redux';
import store from './store';

//## STYLES
import './assets/scss/settings.scss';

// ## COMPONENTS
import Header from './components/Header/header';
import Menu from './components/Menu/menu';
import LeftDrawer from './components/Drawer/Drawer';
import RightDrawer from './components/DrawerRight/Drawer';

//# PAGES
import Login from './components/Pages/Login/login';
import Dasboard from './components/Pages/Dashboard/Dashboard';
import ClientsManagement from './components/Pages/Clients/ClientsManagement';
import ClientEdit from './components/Pages/Clients/Client/Client';
import EventsManagement from './components/Pages/Events/EventsManagement';
import InvoicesManagement from './components/Pages/Invoices/InvoicesManagement';
import ServicesManagement from './components/Pages/Services/ServicesManagement';
import PagesManagement from './components/Pages/Pages/PagesManagement';
import ProjectsManagement from './components/Pages/Projects/ProjectsManagement';
import ProjectEdit from './components/Pages/Projects/Project/Project';
import FileManagement from './components/Pages/Files/FileManagement';
import AdminUsers from './components/Pages/Users/Users';


//# 404
import NotFound from './components/Pages/NotFound/404';
//import Redirect from './components/Redirect/Redirect';

const adjustHeight = {
  minHeight: "100vh"
};

if (localStorage.auth) {
  setAuthToken(localStorage.auth);//set auth token to local storage
  const decoded = jwt_decode(localStorage.auth);
  store.dispatch(setCurrentUser(decoded))
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location = '/adminr-login';
    //this.props.history.push('/adminr-login');
  }
}

class App extends Component {
  render() {
    //console.log(this.props)
    return (
      <Provider store={store}>
        <Router>
          <div className="grid-y">
            <div className="off-canvas-wrapper" style={adjustHeight}>
              <div className="off-canvas-absolute position-left blue-bg-r" id="offCanvasMenu" data-off-canvas data-transition="push" data-is-revealed="true">
                <Menu />
              </div>
              <div className="off-canvas-content" data-off-canvas-content >
                <div id="masterLayer" className="grid-x">
                  <div className="cell auto">
                    <Header />
                    <LeftDrawer />
                    <div className="grid-container full">
                      <div className="grid-x">
                        <div className="cell large-12 medium-12 small-12  adminContentContainer">
                          <Route render={({ location }) => (
                            <TransitionGroup>
                              <CSSTransition key={location.key} classNames="fade" timeout={300} >
                                <Switch location={location}>
                                  <Route path="/dashboard" exact component={Dasboard} />
                                  <Route path="/clients-management" exact component={ClientsManagement} />
                                  <Route path="/clients-edit/:id" exact component={ClientEdit} />
                                  <Route path="/events-management" exact component={EventsManagement} />
                                  <Route path="/invoices-management" exact component={InvoicesManagement} />
                                  <Route path="/services-management" exact component={ServicesManagement} />
                                  <Route path="/pages-management" exact component={PagesManagement} />
                                  <Route path="/projects-management" exact component={ProjectsManagement} />
                                  <Route path="/project-edit/:id" exact component={ProjectEdit} />
                                  <Route path="/files-management" exact component={FileManagement} />
                                  <Route path="/admin-users-management" exact component={AdminUsers} />
                                  <Route path="/adminr-login" exact component={Login} />
                                  <Route render={() => <NotFound />} />
                                </Switch>
                              </CSSTransition>
                            </TransitionGroup>
                          )} />
                          <div className="dashError red"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <RightDrawer />
                </div>
              </div>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
