import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import loginIcon from '../../../images/ui/log-in.svg';
import { connect } from 'react-redux';
import { loginUser } from '../../../actions/authActions'; 

import './login.scss';

const dateToday = new Date();

const year = dateToday.getFullYear();

class Login extends Component{
  constructor(){
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      submitting: false,
      validForm: false,
      submitResponse: ''
    };
    this.inputChange = this.inputChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount(){
    if(this.props.auth.isAuthenticated){
      window.location = '/dashboard';
      //this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.auth.isAuthenticated){
      //this.props.history.push('/dashboard');
      window.location = '/dashboard'; //use this method if not using history
    }
    if(nextProps.errors){
      this.setState({
        errors: nextProps.errors,
        validForm: false,
      })
    }
  }// when receives new props from redux

  inputChange(e){
    this.setState({[ e.target.name ]: e.target.value });
  }

 formSubmit(e){
    e.preventDefault();

    setTimeout(() => {
      this.setState({
        submitting: true
      });
    }, 500);

    const user = {
      email: this.state.email,
      password: this.state.password
    }
    
    this.props.loginUser(user);// setting the login action and redirect param
  }

  render(){

    const {errors} = this.props.errors;

    const {email, password, validForm, submitting } = this.state;

    let formConfirmation = <div className={`cell large-12 f-12 strong margin-distributed ${validForm ? 'green':'red'}`} dangerouslySetInnerHTML={{ __html: this.state.submitResponse }}></div>

    let sending;

    if(submitting){
      sending = "AUTHENTICATING...";
    }else{
      sending = "LOG IN";
    }

    return(
      <section className="full-section">
        <div className="cell" id="login">

        <div className="white-bg center padding-all round-medium shadow_1">
          
          <form data-abide noValidate id="logIn" method="post" onSubmit={this.formSubmit}>
          <h4 className="lato">
            <img src={loginIcon} width="35" alt="login-icon"/>
          </h4>
          <div className="input-group">
            <span className="input-group-label">
            <div href="#"  data-toggle="dropdown-1" data-tooltip aria-haspopup="true" className="has-tip top" data-disable-hover="false" tabIndex="1" title="Click here to know how to log in">
              <i className="fas fa-user-shield"></i>
            </div>
            </span>
            <input className={`input-group-field ${email ? 'is-invalid-input':''}`} type="text" name="email" placeholder="Email" id="email" maxLength="255" onChange={this.inputChange}/>
          </div>
          <div className="input-group password">
            <span className="input-group-label">
              <a href="/recover-access" data-tooltip aria-haspopup="true" className="has-tip top" data-disable-hover="false" tabIndex="1" title="Click here if you lost your password"><i className="fas fa-key"></i></a>
            </span>
            <input className={`input-group-field ${password ? 'is-invalid-input':''}`} type="password" placeholder="Password" name="password" id="password" maxLength="50" onChange={this.inputChange} />
          </div>
          {formConfirmation}
          {errors}
          <div className="grid-x grid-margin-x">
            <div className="large-12 medium-12 small-12 cell">
            <input className="expanded button hollow round-large" type="submit" value={sending} />
            </div>
          </div>
          </form>
        </div>
          <div className="cell f-10 margin-top">© EFX PRO Media - {year} - All Rights Reserved</div>
        </div>
        
        <div className="dropdown-pane" id="dropdown-1" data-dropdown data-auto-focus="true">
          <button className="close" data-close type="button">
          <span aria-hidden="true" className="red">&times;</span>
          </button>
          <h3>How to log in?</h3>
          <p> - If oyu are a registered EFX client, use the details we emailed you to access your account.
          - If you have forgotten your password click on the question mark next to the password field to retrieve access.
          </p>
          <p className="f-10 red">TO CHANGE YOUR LOG IN DETAILS PLEASE LOG IN AND CHANGE THEM AT YOUR ACCOUNT SETTINGS</p>
        </div>
      </section>
    )
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {loginUser})(withRouter(Login));