import axios from 'axios';
import { GET_CLIENTS, GET_CLIENT, CLIENTS_LOADING } from './types'; //DELETE_CLIENTS


// ### GET CLIENTS
export const getClients = () => dispatch => {

  dispatch(isLoading());

  axios({
    method: 'get',
    url:'/clients',  
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_CLIENTS", res.data);

    dispatch({
      type: GET_CLIENTS,
      payload: res.data.clients
    });

  }).catch( err => {

    dispatch({
      type: GET_CLIENTS,
      payload: null
    });

  });//axios

}// CLIENTS DISPATCH GET_CLIENTS


export const getClient = (id) => dispatch => {

  dispatch(isLoading());

  axios({
    method: 'get',
    url:`/clients/${id}`, 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_CLIENT", res.data);

    dispatch({
      type: GET_CLIENT,
      payload: res.data.client
    });

  }).catch( err => {

    dispatch({
      type: GET_CLIENT,
      payload: null
    });

  });//axios

}// PROJECTS DISPATCH GET_PROJECTS

export const isLoading = () => {
  return {
    type: CLIENTS_LOADING
  }
}

// export const clientsList = (clients) => {
//   return {
//     type: GET_CLIENTS,
//     payload: clients
//   }
// }




//### POST CLIENTS

// export const postClient = (clientData) = dispatch => {

// }

//### DELETE CLIENTS

// export const deleteClient = (clientID) = dispatch => {

// }