import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import $ from "jquery";
import UploadImg from '../../../assets/images/ui/add-image-wide.png';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules, formats } from '../../TextEditor/TextEditorConfig';

//MATERIAL UI 
import {
  Button,
  AppBar,
  Toolbar,
  Dialog,
  Slide,
  DialogContent,
  DialogActions,
  Typography,
  Switch
} from '@material-ui/core';

class AddItemFullDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitResponse: '',
      validForm: false,
      submitting: false,
      isVerified: false,
      description: '<p>Coming Soon...</p>',
      mainImage: '',
      deleteMainImage: false,
      isChecked: false,
      files: null,
      user: {}
    }
    this.inputChange = this.inputChange.bind(this);
    this.switchChange = this.switchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = '';
      this.deleteUpload(this.state.mainImage);
    });// THIS PREVENTS THE USER FROM CREATING IMAGES THAT WILL NOT UPLOAD
  }

  componentWillReceiveProps() {
    this.setState({
      user: this.props.auth.user
    });
  }// COMPONENT WILL RECEIVE PROPS

  handleChange(e) {
    this.setState({ description: e });
  }

  switchChange() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  inputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  fileChange(e) {
    this.setState({ files: e.target.files[0] });
    setTimeout(() => {
      console.log(this.state.files);
      const uploadForm = document.getElementById("uploadFile"); // or document.forms
      uploadForm.dispatchEvent(new Event('submit'));
    }, 500);
  }

  uploadSubmit(e) {
    e.preventDefault();
    const form = document.getElementById("uploadFile"); // or document.forms
    const formData = new FormData(form);
    formData.fileUpload = this.state.files;
    console.log(formData);

    axios({
      method: 'post',
      url: '/projects/upload',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(response => {
      console.log(response);
      if (response.status === 201) {
        this.setState({
          mainImage: response.data.filePath,
          deleteMainImage: true
        });
      }
    }).catch(error => {
      console.log("Error Uploading", error);
    });

  }// FILE UPLOAD

  formSubmit(e) {
    e.preventDefault();

    setTimeout(() => {
      this.setState({
        submitting: true
      });
    }, 800);

    const form = document.getElementById("createProjects"); // or document.forms
    const formData = new FormData(form);

    const values = {};
    for (let [key, value] of formData.entries()) {
      if (values[key]) {
        if (!(values[key] instanceof Array)) {
          values[key] = new Array(values[key]);
        }
        values[key].push(value);
      } else {
        values[key] = value;
      }
    }// RECYCLABLE PROCESS TO GET VALUES FROM FIELDS IN REACT

    values.description = this.state.description;

    let types = $("#projectType").val();
    let typesArr = [];
    //types = types.replace(/\s/g, '');//remove any space maybe dont!
    let strArr = types.split(",");

    $.each(strArr, function (i, item) {
      let items = { "item": item };
      typesArr.push(items);
    });

    values.projectType = typesArr;
    values.isActive = this.state.isChecked;

    console.log("Form Values", values);

    axios({
      method: 'post',
      url: '/projects',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      },
      data: values
    }).then(response => {
      console.log(response);
      if (response.status === 201) {
        this.setState({
          submitResponse: "Project Created",
          validForm: true,
          submitting: false
        });
        setTimeout(() => {
          this.props.handleClose();
          //this.props.getPages();
          this.setState({
            description: '',
            submitResponse: ''
          });

        }, 1000);//
      }
    })
      .catch(error => {
        console.log(error);

        setTimeout(() => {
          this.setState({
            submitResponse: "Please review the form details before sending",
            validForm: false,
            submitting: false
          });
        }, 1000);
      });

    //console.log("contact form: " + JSON.stringify(values));
  }

  clickSubmit() {
    const form = document.getElementById("createProjects"); // or document.forms
    form.dispatchEvent(new Event('submit'));
  }

  deleteUpload(file) {
    axios({
      method: 'post',
      url: '/file/delete',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: {
        filePath: file
      }
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        this.setState({
          mainImage: ''
        });
      }
    })
      .catch(error => {
        console.log(error);
      });

  }// DELETE FILE

  render() {

    const { validForm, submitResponse, user, description, isChecked, mainImage, deleteMainImage } = this.state;
    const { dialogIsOpen, handleClose } = this.props;
    const { clientsData } = this.props.clients;

    let formConfirmation = <div className={`cell large-12 strong ${validForm ? 'green' : 'red'}`} dangerouslySetInnerHTML={{ __html: this.state.submitResponse }}></div>
    let sending;

    if (this.state.submitting) {
      sending = "SENDING";
    } else {
      sending = "ADD PROJECT";//change the send button
    }

    return (
      <Dialog fullScreen open={this.props.dialogIsOpen} onClose={handleClose}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
              ADD A PROJECT
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              CLOSE
            </Button>
          </Toolbar>
        </AppBar>
        <Slide direction="up" in={dialogIsOpen} mountOnEnter unmountOnExit>
          <DialogContent className="margin-top-3x">
            <div className="grid-container">
              <div className="grid-x grid-margin-x grid-padding-x">
                <div className="cell large-12 medium-12 margin-top-2x">

                  <form id="uploadFile" onSubmit={this.uploadSubmit.bind(this)} ref={upload => this.form = upload}>
                    <div className="input-image-upload large-6 medium-6 small-12 cell">
                      <div className="callout center">
                        <label htmlFor="fileUpload" data-tooltip title="CLICK TO UPLOAD">
                          <img id="image-1" src={mainImage ? mainImage : UploadImg} alt="upload" width="100%" />
                          <span className="imageName">UPLOAD</span>

                        </label>
                        <input type="file" name="fileUpload" id="fileUpload" className="inputfile inputfile-2" onChange={this.fileChange} />
                        {deleteMainImage ?
                          <Button color="inherit" onClick={() => this.deleteUpload(mainImage)}>
                            REMOVE IMAGE
                      </Button> :
                          ''}
                      </div>
                    </div>
                  </form>

                  <form data-abide noValidate id="createProjects" onSubmit={this.formSubmit.bind(this)} encType="multipart/form-data" ref={el => this.form = el}>
                    <Switch
                      onChange={this.switchChange}
                      checked={isChecked}
                    />

                    <label htmlFor="name">mainImage
                      <input type="text" name="mainImage" id="mainImage" aria-errormessage="titleErr" required value={mainImage} onChange={this.inputChange} />
                      <span className="form-error" id="titleErr">
                        Main Image!!
                      </span>
                    </label>

                    <label htmlFor="name">Name
                    <input type="text" name="name" id="name" aria-errormessage="titleErr" required onChange={this.inputChange} />
                      <span className="form-error" id="titleErr">
                        How should you call this?
                    </span>
                    </label>

                    <label htmlFor="clientList">Client
                      <select name="clientList" id="clientList" aria-errormessage="clientListErr" required onChange={this.inputChange}>
                        {clientsData.map((client) =>
                          <option key={client._id} value={client._id}> {client.name} {client.surname}</option>
                        )}
                      </select>
                      <span className="form-error" id="clientListErr">
                        Its good to know who wanted it!
                      </span>
                    </label>

                    <label htmlFor="logoImage">Project Logo
                <input type="text" name="logoImage" id="logoImage" aria-errormessage="logoImageErr" required onChange={this.inputChange} />
                      <span className="form-error" id="logoImageErr">
                        Unless he/she doesn't have a father or a mother.
                </span>
                    </label>

                    <label htmlFor="createdBy">Created By {user.name} {user.surname}
                      <input type="text" name="createdBy" id="createdBy" aria-errormessage="dateCreatedErr" value={user.userId} readOnly />
                      <span className="form-error" id="createdByErr">
                        Who Created it?
                </span>
                    </label>

                    <label htmlFor="dateCreated">Created Date
                <input type="date" name="dateCreated" id="dateCreated" aria-errormessage="dateCreatedErr" required onChange={this.inputChange} />
                      <span className="form-error" id="dateCreatedErr">
                        Need a date plzzz...
                </span>
                    </label>

                    <label htmlFor="businessType">Business Type
                <input type="text" name="businessType" id="businessType" aria-errormessage="businessTypeErr" required onChange={this.inputChange} />
                      <span className="form-error" id="businessTypeErr">
                        What business is it in?
                </span>
                    </label>

                    <label htmlFor="projectUrl">Internal Project URL
                <input type="text" name="projectUrl" id="projectUrl" aria-errormessage="projectUrlErr" onChange={this.inputChange} />
                      <span className="form-error" id="projectUrlErr">
                        If a dynamic content is available trow it here.
                </span>
                    </label>

                    <label htmlFor="liveUrl">Live URL
                <input type="text" name="liveUrl" id="liveUrl" aria-errormessage="liveUrlErr" onChange={this.inputChange} />
                      <span className="form-error" id="liveUrlErr">
                        Where do I see it live!
                </span>
                    </label>

                    <label htmlFor="status">Status
                <select name="status" id="status" aria-errormessage="statusErr" required onChange={this.inputChange}>
                        <option value="live">Live</option>
                        <option value="archived">Archived</option>
                      </select>
                      <span className="form-error" id="statusErr">
                        Its good to know who wanted it!
                </span>
                    </label>
                    {formConfirmation}
                  </form>
                </div>

                <div className="cell large-6 margin-top-2x">
                  <div id="types">
                    <label htmlFor="projectType">Project Type (Spareted by comma)
                  <input type="text" name="projectType" id="projectType" aria-errormessage="projectTypeErr" required onChange={this.inputChange} />
                      <span className="form-error" id="projectTypeErr">
                        Unless he/she doesn't have a father or a mother.
                  </span>
                    </label>
                  </div>

                  <label htmlFor="description">Project Description</label>
                  <ReactQuill
                    value={description}
                    placeholder="Enter your page Content"
                    onChange={this.handleChange}
                    theme="snow"
                    modules={modules}
                    formats={formats} />


                </div>


              </div>
            </div>

          </DialogContent>
        </Slide>
        <DialogActions>
          <Typography>
            {submitResponse}
          </Typography>
          <Button onClick={() => { this.deleteUpload(mainImage); handleClose() }} color="secondary">
            CANCEL
        </Button>
          <Button onClick={() => this.clickSubmit()} color="primary" autoFocus>
            {sending}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddItemFullDialog.propTypes = {
  formSubmit: PropTypes.func,
  uploadSubmit: PropTypes.func,
  clientsData: PropTypes.object
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
  projects: state.projects
});

export default connect(mapStateToProps)(AddItemFullDialog);