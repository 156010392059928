import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './close-morph.scss';

class CloseMorph extends Component{
  render(){
  const {left} = this.props.drawers;
   //console.log("Close morph", this.props.drawers);

    return(
      <div id="nav-lines" className={left.open ? "active":""}>
        <svg viewBox="0 0 64 64">
          <line id="nav-line-1" x1="8" x2="56" y1="16" y2="16" className="nav-line" />
          <line id="nav-line-2" x1="8" x2="50" y1="32" y2="32" className="nav-line" />
          <line id="nav-line-3" x1="8" x2="56" y1="48" y2="48" className="nav-line" /> 
          <line x1="16" x2="48" y1="16" y2="48" className="cross-line" /> 
          <line x1="16" x2="48" y1="48" y2="16" className="cross-line" /> 
      </svg>
    </div>
    )
  }
}

CloseMorph.propTypes = {
  drawers: PropTypes.object, 
}

const mapStateToProps = (state) => ({
  drawers: state.drawers
});

export default connect(mapStateToProps)(CloseMorph);