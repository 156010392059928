import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Moment from 'react-moment';

import { pageAttributes } from '../../../actions/authActions';
import { getProjects } from '../../../actions/projectsActions';
import { getClients } from '../../../actions/clientsActions';

import axios from 'axios';
import Spinner from '../../../assets/images/ui/spinner.svg';

import AddItemFullDialog from './addItemFullDialog';
import DeleteAlert from '../../Alerts/DeleteAlert';

import {
  List,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Tooltip,
  Chip,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  CardContent,
  Collapse
} from '@material-ui/core';

import {
  Delete,
  ExpandMore,
  Edit,
  ArrowForward,
  MoreVert,
  Notifications
} from '@material-ui/icons';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class ProjectsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      alertRequestUrl: null,
      dialogIsOpen: false,
      copied: false,
      projectInfo: {},
      expanded: false
    }

    this.getProjectInfo = this.getProjectInfo.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }// CONSTRUCTOR

  componentDidMount() {

    this.props.getClients();
    this.props.getProjects();

    const page = {
      name: "Projects"
    };// include theme changes in this object

    this.props.pageAttributes(page);
  }//COMPONENT DID MOUNT

  handleExpandClick() {
    this.setState(state => ({ expanded: !state.expanded }));
  }
  handleClickOpen() {
    this.setState({ dialogIsOpen: true });
  };

  handleClose() {
    this.setState({ dialogIsOpen: false });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  deleteItem(itemID) {
    console.log("delete ID", itemID);

    axios({
      method: 'get',
      url: '/projects/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      console.log(res.data);

      let project = res.data.project;

      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete Project?",
        alertContent: project.name,
        alertData: res.data,
        alertRequestUrl: `/projects/${project._id}`
      });
    }).catch(err => {
      console.log(err);
    });//axios

  }// DELETE

  getProjectInfo(itemID) {
    axios({
      method: 'get',
      url: '/projects/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      console.log("GET_PROJECT", res.data);

      this.setState({
        projectInfo: res.data.project,
      });

    }).catch(err => {

      console.log(err)

    });//axios
  }

  updateCopy() {
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  }



  render() {

    const theme = createMuiTheme({
      palette: {
        primary: {
          light: '#757ce8',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000'
        }
      },
      typography: {
        useNextVariants: true,
      }
    });

    const {
      dialogIsOpen,
      confirmAlertIsOpen,
      alertHeader,
      alertContent,
      alertRequestUrl,
      alertData,
      projectInfo,
    } = this.state;

    const {
      projectsData,
      loading
    } = this.props.projects;


    //const list = Array.from(projectsData);// turn it into array
    const itemsCount = projectsData.length;

    let itemsList;

    if (projectsData.length > 0) {
      itemsList = (
        <List dense>
          {projectsData.map(project =>
            <ListItem key={project._id} alignItems="flex-start"  button onClick={() => this.getProjectInfo(project._id)}>
              <ListItemAvatar>
                <Avatar alt={project.name} style={{ width: 60, height: 60 }} src={project.mainImage} />
              </ListItemAvatar>
              <ListItemText
                primary={project.name}
                secondary={
                  <React.Fragment>
                    <Typography component="span" color="textPrimary">
                      {project.client ? project.client.name + " " + project.client.surname : ''}
                    </Typography>
                    {project.liveUrl}
                  </React.Fragment>
                }
              />
            </ListItem>
          )}
        </List>
      );//
    } else {
      itemsList = (
        <div> No Projects Loaded</div>
      )
    }

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    let project;


    if (Object.keys(projectInfo).length === 0) {
      project = (
        <div><div className="center padding-all"> SELECT A PROJECT</div></div>
      );
    } else {
      project = (
        <div className="grid-x grid-margin-x white-bg margin-bottom-2x round-small" data-equalizer-watch="header">
          <div className="cell large-12 medium-12 small-12">

            <Card>
              <CardHeader
                avatar={
                  <Avatar alt={projectInfo.name} src={projectInfo.logoImage} className={projectInfo.isActive ? "green-border" : "red-border"} />
                }
                action={
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                }
                title={projectInfo.name}
                subheader={projectInfo.liveUrl}
              />
              <CardMedia style={{ paddingTop: '56.25%' }}
                image={projectInfo.mainImage}
                title={projectInfo.name}
              />
              <CardContent>
                <div className="cell large-12 medium-12 small-12 f-18 gray_2-border-top">
                  Client: {projectInfo.client ? projectInfo.client.name : "No Client!"} {projectInfo.client ? projectInfo.client.surname : ""}
                </div>

                <div className="cell large-6 medium-6 small-12 f-18 gray_2-border-top">
                  Created: <Moment format="DD/MM/YYYY">
                    {projectInfo.dateCreated}
                  </Moment>
                </div>

                <div className="cell large-6 medium-6 small-12 f-18 gray_2-border-top">
                  Business Type: {projectInfo.businessType}
                </div>

                <div className="cell large-12 medium-12 small-12 f-18">
                  Type: {projectInfo.projectType.map(type =>
                    <Chip
                      key={type._id}
                      label={type.item}
                      className="margin-all"
                    />
                  )}
                </div>
              </CardContent>
              <CardActions disableActionSpacing>
                <Tooltip title="Delete" placement="top">
                  <IconButton aria-label="Delete">
                    <Delete onClick={() => this.deleteItem(projectInfo._id)} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit" placement="top">
                  <Link to={`/project-edit/${projectInfo._id}`}>
                    <IconButton aria-label="Edit">
                      <Edit />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="View Live" placement="top">
                  <IconButton aria-label="View Live">
                    <Link to={projectInfo.liveUrl} target="_blank">
                      <ArrowForward color="action" />
                    </Link>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notification" placement="top">
                  <IconButton aria-label="Notification">
                    <Notifications />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Description" placement="top">
                  <IconButton
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                    color="primary"
                    className="float-right"
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              </CardActions>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography>
                    {renderHTML(projectInfo.description)}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </div>
        </div>
      );
    }


    return (
      <MuiThemeProvider theme={theme}>
        <div className="grid-x grid-margin-x grid-padding-x">
          <div className="cell margin-top-2x">
            <div className="grid-x grid-padding-x" data-equalizer="header">
              <div className="cell large-6 medium-6 small-12">
                <div className="grid-x grid-margin-x white-bg margin-bottom-2x padding-all" data-equalizer-watch="header">
                  <div className="cell f-20" id="dataCount">
                    <IconButton color="primary" onClick={this.handleClickOpen.bind(this)}>
                      <i className="fa fa-plus" style={{ fontSize: 20 }}></i>
                    </IconButton>
                    {itemsCount} PROJECTS
                  </div>
                  <div className="cell center">
                    {loading ? "" : itemsList}
                    {loading ? contentLoading : ""}
                  </div>
                </div>
              </div>
              <div className="cell large-6 medium-6 small-12" >
                {project}
              </div>
            </div>
          </div>

          <AddItemFullDialog dialogIsOpen={dialogIsOpen} handleClose={() => this.handleClose()} getProjects={() => this.props.getProjects()} />
          <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
            content={alertContent}
            handleAlertClose={() => this.handleAlertClose()}
            alertHeader={alertHeader}
            alertData={alertData}
            alertRequestUrl={alertRequestUrl}
            getProjects={() => this.props.getProjects()}
          />

        </div>
      </MuiThemeProvider>
    )
  }
}

ProjectsManagement.propTypes = {
  auth: PropTypes.object.isRequired,
  pageAttributes: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  projectsData: PropTypes.object,
  clientsData: PropTypes.object,
  projectInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
  projects: state.projects
});

export default connect(mapStateToProps, { getProjects, getClients, pageAttributes })(ProjectsManagement);