import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from "jquery"
import UploadImg from '../../../assets/images/ui/add-image-wide.png'
import { connect } from 'react-redux';

//MATERIAL UI 
import {
  Button, 
  AppBar,
  Toolbar,
  Dialog, 
  Slide, 
  DialogContent, 
  DialogActions,
  Typography
} from '@material-ui/core';

class AddItemFullDialog extends Component{

  constructor(props){
    super(props);
    this.state = {
      submitResponse: '',
      validForm: false, 
      submitting: false,
      isVerified: false,
      mainImage: '',
      deleteMainImage: false,
      files: null,
      user: {}
    }
    this.inputChange = this.inputChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);
  }

  componentDidMount(){
    $(document).on("click",".addSite", function (e) {
      e.preventDefault();
      var inputModel =
        '<input type="text" name="site" class="site" aria-errormessage="siteErr" placeholder="http://www.mysite.com" required pattern="url">';
      $("#sites").append(inputModel);
    });

    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = '';
      this.deleteUpload(this.state.mainImage);
    });// THIS PREVENTS THE USER FROM CREATING IMAGES THAT WILL NOT UPLOAD

  }//COMPONENT DID MOUNT

  componentWillReceiveProps() {
    this.setState({
      user: this.props.auth.user
    });
  }// COMPONENT WILL RECEIVE PROPS
  
  inputChange(e){
    this.setState({ [e.target.name]: e.target.value });
  }

  fileChange(e) {
    this.setState({ files: e.target.files[0] });
    setTimeout(() => {
      console.log(this.state.files);
      const uploadForm = document.getElementById("uploadFile"); // or document.forms
      uploadForm.dispatchEvent(new Event('submit'));
    }, 500);
  }

  uploadSubmit(e) {
    e.preventDefault();
    const form = document.getElementById("uploadFile"); // or document.forms
    const formData = new FormData(form);
    formData.fileUpload = this.state.files;
    console.log(formData);

    axios({
      method: 'post',
      url: '/clients/upload',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(response => {
      console.log(response);
      if (response.status === 201) {
        this.setState({
          mainImage: response.data.filePath,
          deleteMainImage: true
        });
      }
    }).catch(error => {
        console.log("Error Uploading", error);
      });

  }// FILE UPLOAD
  
  formSubmit(e){
    e.preventDefault();
  
      setTimeout(() => {
        this.setState({
          submitting: true
        });
      }, 800);  
    
      const form = document.getElementById("createClients"); // or document.forms
      const formData = new FormData(form);
    
      const values = {};
      for (let [key, value] of formData.entries()) {
        if (values[key]) {
          if ( ! (values[key] instanceof Array) ) {
            values[key] = new Array(values[key]);
          }
          values[key].push(value);
        } else {
          values[key] = value;
        }
      }// RECYCLABLE PROCESS TO GET VALUES FROM FIELDS IN REACT


      var siteArr = [];

      $(".site").each(function() {
        var siteUrl = $(this).val();
        siteArr.push({'url':siteUrl});
      }); //building array of sites from inputs

      //console.log("Sites Array", JSON.stringify(siteArr)); 

      values["sites"] = JSON.stringify(siteArr);// PUSHING ARRAYS!

      //console.log("form Values", values);
    
      axios({
          method: 'post',
          url: '/clients/create',
          headers: {
           'Authorization' : "Bearer " + localStorage.getItem('auth')
           },
          data: values
        }).then(response => {
          console.log(response);
          if(response.status === 201){
            this.setState({
              submitResponse: "User Created",
              validForm: true,
              submitting:false
            });
            setTimeout(()=>{
              this.props.handleClose();
            }, 1000);//
          }
        })
        .catch(error => {
          console.log(error);
    
          setTimeout(()=>{
            this.setState({
              submitResponse: "Please review the form details before sending",
              validForm: false,
              submitting: false
            });
          },1000);
        });
  }// CREATE FORM SUBMIT

  clickSubmit(){
    const form = document.getElementById("createClients"); // or document.forms
    form.dispatchEvent(new Event('submit'));
  }

  deleteUpload(file) {
    axios({
      method: 'post',
      url: '/file/delete',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: {
        filePath: file
      }
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        this.setState({
          mainImage: ''
        });
      }
    }).catch(error => {
      if(error.status === 409){
        this.setState({
          submitResponse: "User already using the same email"
        })
      }
      console.log(error);
      });

  }// DELETE FILE

  render(){
    
    const {validForm, submitResponse, user, mainImage, deleteMainImage} = this.state;
    const {dialogIsOpen, handleClose} = this.props;

    let formConfirmation = <div className={`cell large-12 strong ${validForm ? 'green':'red'}`} dangerouslySetInnerHTML={{ __html: this.state.submitResponse }}></div>
    let sending;

    if(this.state.submitting){
      sending = "SENDING";
    }else{
      sending = "ADD CLIENT";//change the send button
    }


    return(
      <Dialog fullScreen open={this.props.dialogIsOpen} onClose={handleClose}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" color="inherit" style={{flexGrow: 1}}>
              ADD CLIENT
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              CLOSE
            </Button>
          </Toolbar>
        </AppBar>
      <Slide direction="up" in={dialogIsOpen} mountOnEnter unmountOnExit>
      <DialogContent className="margin-top-3x">
      <div className="grid-x grid-margin-x grid-padding-x">
            <div className="cell large-6 margin-top-2x">

            <form id="uploadFile" onSubmit={this.uploadSubmit.bind(this)} ref={upload => this.form = upload}>
              <div className="input-image-upload large-6 medium-6 small-12 cell">
                <div className="callout center">
                  <label htmlFor="fileUpload" data-tooltip title="CLICK TO UPLOAD">
                    <img id="image-1" src={mainImage ? mainImage : UploadImg } alt="upload" width="100%" />
                    <span className="imageName">UPLOAD</span>

                  </label>
                  <input type="file" name="fileUpload" id="fileUpload" className="inputfile inputfile-2" onChange={this.fileChange} />
                  {deleteMainImage ?
                    <Button color="inherit" onClick={() => this.deleteUpload(mainImage)}>
                      REMOVE IMAGE
                    </Button> :
                    ''}
                </div>
              </div>
            </form>


              <form data-abide noValidate id="createClients" onSubmit={this.formSubmit.bind(this)} encType="multipart/form-data" ref={el => this.form = el}>

                <label htmlFor="name">Image (URL)
                  <input type="text" name="profileIMage" id="profileIMage" aria-errormessage="imageErr" value={mainImage} onChange={this.inputChange} required/>
                  <span className="form-error" id="imageErr">
                    The client needs some image to be identified
                  </span>
                </label>

                <label htmlFor="name">Name
                  <input type="text" name="name" id="name" aria-errormessage="titleErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="titleErr">
                    How should you call them?
                  </span>
                </label> 

                <label htmlFor="surname">Surname
                  <input type="text" name="surname" id="surname" aria-errormessage="surnameErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="titleErr">
                    Unless he/she doesn't have a father or a mother.
                  </span>
                </label>

                <label htmlFor="company">Company
                  <input type="text" name="company" id="company" aria-errormessage="companyErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="companyErr">
                    You must enter a company here..
                  </span>
                </label>


                <label htmlFor="addressLine1">Address
                  <input type="text" name="addressLine1" id="addressLine1" aria-errormessage="addrErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="addrErr">
                    I need this address..
                  </span>
                </label>

                <label htmlFor="addressLine2">Address Optional
                  <input type="text" name="addressLine2" id="addressLine2" aria-errormessage="addrOpErr" onChange={this.inputChange}/>
                  <span className="form-error" id="addrOpErr">
                    In case you have another line...
                  </span>
                </label>

                <label htmlFor="addressCity">City
                  <input type="text" name="addressCity" id="addressCity" aria-errormessage="cityErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="cityErr">
                    Need a city!
                  </span>
                </label>

                <label htmlFor="addressPostCode">Post Code
                  <input type="text" name="addressPostCode" id="addressPostCode" aria-errormessage="postCodeErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="postCodeErr">
                    Need a valid Postcode
                  </span>
                </label>

                <label htmlFor="addressCountry">Country
                  
                  <select name="addressCountry" id="addressCountry" aria-errormessage="countryErr" onChange={this.inputChange} required>
                    <option value="CA">Canada</option>
                    <option value="UK">United Kingdom</option>
                  </select>
                  <span className="form-error" id="countryErr">
                    Every man needs a country!
                  </span>
                </label>

                <label htmlFor="contactPhoneMobile">Mobile
                  <input type="text" name="contactPhoneMobile" id="contactPhoneMobile" aria-errormessage="mobileErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="mobileErr">
                    Everybody has one!
                  </span>
                </label>

                <label htmlFor="contactPhoneOptional">Landline (Optional)
                  <input type="text" name="contactPhoneOptional" id="contactPhoneOptional" aria-errormessage="mobileOpErr" onChange={this.inputChange}/>
                  <span className="form-error" id="mobileOpErr">
                    Additional Contact
                  </span>
                </label>

                <label htmlFor="dateCrated">Start Date
                  <input type="date" name="dateCrated" id="dateCrated" aria-errormessage="mobileOpErr" onChange={this.inputChange}/>
                  <span className="form-error" id="mobileOpErr">
                    We started something one day!
                  </span>
                </label>

                <label htmlFor="email">Email
                  <input type="email" name="email" id="email" aria-errormessage="emailErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="emailErr">
                    This doe snot appear to be a valid email
                  </span>
                </label>

                <label htmlFor="password">Password
                  <input type="password" name="password" id="password" aria-errormessage="passwordErr" onChange={this.inputChange} required/>
                  <span className="form-error" id="passwordErr">
                    Enter a password
                  </span>
                </label>

                <label htmlFor="passwordConfirm">Password Confirm
                  <input type="password" name="passwordConfirm" id="passwordConfirm" aria-errormessage="passwordConfErr" data-equalto="password" onChange={this.inputChange} required/>
                  <span className="form-error" id="passwordConfErr">
                    Your passwords don't match, Please make sure it is the same.
                  </span>
                </label>

                <label htmlFor="createdBy">Created By {user.name} {user.surname}
                <input type="text" name="createdBy" id="createdBy" aria-errormessage="dateCreatedErr" value={user.userId} readOnly />
                  <span className="form-error" id="createdByErr">
                    Who Created it?
                </span>
                </label>

                {formConfirmation}

                {/* <div className="button-group small hollow margin-top">
                  <input type="submit" className="button hollow small" value={sending}/>
                  <button data-close="addItemDialog" className="button alert hollow small no-margin">CANCEL</button>
                </div> */}

              </form>
            </div>

            <div className="cell large-6 margin-top-2x">
              <div id="sites">
                <label htmlFor="site">Sites
                  <input type="text" name="site" className="site" aria-errormessage="siteErr" placeholder="http://www.mysite.com" required pattern="url" />
                  <span className="form-error" id="siteErr">
                    Yo, you had better fill this out, it's required.
                  </span>
                </label>
              </div>
              <button className="button tiny hollow addSite" >ADD SITE</button>
            </div>
          </div>

      </DialogContent>
      </Slide>
      <DialogActions>
        <Typography>
        {submitResponse}
        </Typography>
        <Button onClick={() => { this.deleteUpload(mainImage); handleClose() }} color="secondary">
          CANCEL
        </Button>
        <Button onClick={()=> this.clickSubmit()} color="primary" autoFocus>
          {sending}
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}

AddItemFullDialog.propTypes = {
  auth: PropTypes.object.isRequired,
  formSubmit: PropTypes.func
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AddItemFullDialog);