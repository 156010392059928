import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pageAttributes } from '../../../actions/authActions';
import { getClients } from '../../../actions/clientsActions';
import axios from 'axios';
import Spinner from '../../../assets/images/ui/spinner.svg';

import AddItemFullDialog from './addItemFullDialog';
import DeleteAlert from '../../Alerts/DeleteAlert';
import _ from 'lodash';
 
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  AppBar,
  IconButton,
  Avatar
} from '@material-ui/core';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class ClientsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      dialogIsOpen: false,
      alertRequestUrl: null,
      search:'',
      clients: []
    }
    this.inputChange = this.inputChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this)
  }// CONSTRUCTOR

  componentDidMount() {
    this.props.getClients();
    const page = {
      name: "Clients"
    };// include theme changes in this object

    this.props.pageAttributes(page);
  }//COMPONENT DID MOUNT


  componentWillReceiveProps(props){
    this.setState({
      clients: props.clients.clientsData
    })
  }

  inputChange(e) {
    this.setState({ 
      search: e.target.value,
      clients: _.filter(this.state.clients, {name: this.state.search})
    });
    console.log(this.state.search);
  }

  handleClickOpen() {
    this.setState({ dialogIsOpen: true });
  };

  handleClose() {
    this.setState({ dialogIsOpen: false });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  deleteItem(itemID) {
    console.log(itemID);

    axios({
      method: 'get',
      url: '/clients/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      console.log(res.data);

      let client = res.data.client

      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete user?",
        alertContent: client.name + " " + client.surname,
        alertData: res.data.client,
        alertRequestUrl: `/clients/${client._id}`
      });
    }).catch(err => {
      console.log(err);
    });//axios

  }// DELETE


  render() {

    const theme = createMuiTheme({
      palette: {
        primary: {
          light: '#757ce8',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000'
        }
      },
      typography: {
        useNextVariants: true,
      }
    });

    const { dialogIsOpen, confirmAlertIsOpen, alertHeader, alertContent, alertData, clients, alertRequestUrl, } = this.state;

    const { clientsData, loading } = this.props.clients;

    const list = Array.from(clients);// turn it into array

    const clientsCount = clientsData.length;

    let clientsList;

    if (clientsData) {
      clientsList = (
        <TableBody>
          {list.map((client) =>
            <TableRow key={client._id}>
              <TableCell component="th" scope="row">
                <Avatar className="margin-all" style={{ backgroundColor: "primary" }}>
                  {client.name[0]}{client.surname[0]}
                </Avatar>
              </TableCell>
              <TableCell component="th" scope="row"> {client.name} {client.surname} </TableCell>
              <TableCell>{client.email}</TableCell>
              <TableCell>{client.company}</TableCell>
              <TableCell>
                <Link to={`/clients-edit/${client._id}`}>
                  <IconButton color="primary"><i className="fa fa-pen" style={{ fontSize: 15 }}></i></IconButton>
                </Link>
              </TableCell>
              <TableCell>
                <IconButton color="secondary" aria-label="Add Client" onClick={() => this.deleteItem(client._id)}>
                  <i className="fa fa-window-close" style={{ fontSize: 15 }}></i>
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      );//
    } else {
      clientsList = (
        <div> No Clients Loaded</div>
      )
    }

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    return (
      <MuiThemeProvider theme={theme}>
        <div className="grid-x grid-margin-x grid-padding-x ">

          <div className="large-12 medium-12 small-12 cell margin-top-2x">
            <Paper>
            <AppBar position="static" color="default" className="padding-distributed">
                <div className="grid-x grid-margin-x grid-padding-x">
                  
                  <div className="cell large-3 medium-4 small-4 white counter">
                  <Typography variant="h6">
                   {clientsCount} CLIENTS
                   </Typography>
                  </div>
                  <div className="cell large-6 medium-6 small-6">
                    <input type="text" name="search" className="search-MU round-small" placeholder="Search" onChange={this.inputChange} />
                  </div>
                  <div className="cell large-3 medium-2 small-2 right">
                    <Button color="primary" variant="contained" onClick={this.handleClickOpen}>
                      + CLIENT
                     </Button>
                  </div>
                </div>
              </AppBar>
              <div style={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>EDIT</TableCell>
                      <TableCell>DELETE</TableCell>
                    </TableRow>
                  </TableHead>

                  {loading ? "" : clientsList}
                </Table>
              </div>
            </Paper>
            {loading ? contentLoading : ""}
          </div>

          <AddItemFullDialog dialogIsOpen={dialogIsOpen} handleClose={() => this.handleClose()} />
          <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
            content={alertContent}
            handleAlertClose={() => this.handleAlertClose()}
            alertHeader={alertHeader}
            alertRequestUrl={alertRequestUrl}
            alertData={alertData}
          />

        </div>
      </MuiThemeProvider>
    )
  }
}

ClientsManagement.propTypes = {
  auth: PropTypes.object.isRequired,
  pageAttributes: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  clientsData: PropTypes.object
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients
});

export default connect(mapStateToProps, { getClients, pageAttributes })(ClientsManagement);