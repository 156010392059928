import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import axios from 'axios';

import Swiper from 'swiper';
import './swiper.scss';
import Spinner from '../../../assets/images/ui/spinner.svg';

class Projects extends Component{
  constructor(props){
    super(props);
    this.state = {
      projects: [],
      loading: false
    }
  }

  componentDidMount(){

    this.setState({
      loading: true
    })

    const swiper = new Swiper('.swiper-container', {
      init: false,
      slidesPerView: 3,
      spaceBetween: 10,
      direction: 'horizontal',
      loop: true,

      // If we need pagination 
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10, 
        }
      }
    });// SWIPER

    
    axios({
      method: 'get',
      url:'/projects',
      headers: {
        'Authorization' : "Bearer " + localStorage.getItem('auth')
      }
    }).then( res => {
      //console.log(res.data);
      this.setState({
        projects: res.data.projects,
        loading: false
      })
      swiper.init();// only initialise the slider when content has loaded to slider

    }).catch( err => {
      console.log(err);
    });//axios
  }// componentDidMount


render(){

  const {loading, projects} = this.state;

    let contentRender;

    if(loading){
      contentRender = <div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader"/> </div>
    }else{
      contentRender = <div className="swiper-wrapper">
          {projects.map( (i) => 
            <div className="swiper-slide cell large-4" key={i._id}>
            <Link to={`/project-edit/${i._id}`}>
              <div className="catalogue-medium gray_2-border white-bg margin-distributed round-small" style={{backgroundImage: `url('${i.mainImage.toString().replace('public', '')}')`}}>
                <div className="dark-overlay"></div>
                <div className="tittle-bottom padding-all"><span className="white strong">{i.name}</span></div>
              </div>
              </Link>
            </div> 
          )}
      </div>
    }

  return (
    <div className="swiper-container">
      {contentRender}
      <div className="swiper-pagination"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
      <div className="swiper-scrollbar"></div>
    </div>
  )
}
}

Projects.proptypes = {
  auth: PropTypes.object.isrequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Projects);