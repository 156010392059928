import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
//import {Link} from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';

import Spinner from '../../../assets/images/ui/spinner.svg';

class Events extends Component{
  constructor(props){
    super(props);
    this.state = {
      events: [],
      loading: false
    }
  }

  componentDidMount(){

    this.setState({
      loading: true
    })

    axios({
      method: 'get',
      url:'/events',
      headers: {
        'Authorization' : "Bearer " + localStorage.getItem('auth')
      }
    }).then( res => {
      console.log(res.data);
      this.setState({
        events: res.data.events,
        loading: false
      })
    }).catch( err => {
      console.log(err);
    });//axios
  }// componentDidMount


render(){

  const {loading, events} = this.state;


  const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader"/> </div>);


  const contentRender = (<div>
    {events.map( (event) => 

      <div className="margin-all gray_2-border padding-all round-medium red-border" key={event._id}>
        <div className="f-16 strong">{event.client}</div>
        <div className="f-14">
        {event.title} 
        <Moment> {event.start} </Moment>  to <Moment format="DD/MM/YYYY"> {event.end} </Moment> <i className="far fa-bell"></i>
        </div>
      </div>
      )}
    </div>
  )

  return (
    <div>
      {loading ? contentLoading : contentRender }
    </div>
  )
}
}

Events.proptypes = {
  auth: PropTypes.object.isrequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Events);