import { GET_PAGES, PAGES_LOADING, DELETE_PAGE } from '../actions/types';
 
const initialState = {
  pagesData: [], 
  loading: false
}

//services has to be an object because it contains the services list and the count

export default function(state = initialState, action){
  switch(action.type){
    case GET_PAGES:
      return {
        ...state,
        pagesData: action.payload,
        loading: false
      };
      case DELETE_PAGE:
       return {
         ...state,
         pagesData: action.payload
       };
      case PAGES_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
    return state;
  }
}