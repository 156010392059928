import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions/authActions';
import {Link} from "react-router-dom";

import { withRouter } from "react-router";

import { toggleDrawerL, toggleDrawerR } from '../../actions/drawerActions';

// ICONS IMPORT
import options from '../../images/ui/options.svg';
import clients from '../../images/ui/clients.svg';
import services from '../../images/ui/services.svg';
import slider from '../../images/ui/slider.svg';
import invoice from '../../images/ui/invoice.svg';
import payments from '../../images/ui/payments.svg';
import chip from '../../images/ui/chip.svg';

import efxLogo from '../../images/EFX-2015.svg';

import CloseMorph from '../CloseMorph/close-morph';


import {  
  Drawer,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Tooltip
  } from '@material-ui/core';

import {
  Settings, 
  ExitToApp,
  SupervisedUserCircle
} from '@material-ui/icons';

class LeftDrawer extends Component{

  onLogoutClick(e){
    e.preventDefault();
    this.props.logoutUser();
  }//logs out the user 

  clickToggler(){
    this.props.toggleDrawerL(this.props.drawers.left.open);
  }

  linkToLocation(url){
    this.props.history.push(url);
  }

  render(){

    const {isAuthenticated, user} = this.props.auth; 
    const {left} = this.props.drawers;

    const userControlLinks = (
      <BottomNavigation>  
        <Tooltip title="Settings" placement="top">
          <BottomNavigationAction onClick={()=>this.linkToLocation("/settings")} label="Settings" icon={<Settings/>} />
        </Tooltip>
        <Tooltip title="Users" placement="top">
          <BottomNavigationAction onClick={()=>this.linkToLocation("/admin-users-management")} label="Users" icon={<SupervisedUserCircle/>} />
        </Tooltip>
        <Tooltip title="Logout" placement="top">
          <BottomNavigationAction style={{flex: 1}} label="Logout" icon={<ExitToApp color="secondary"/>} onClick={this.onLogoutClick.bind(this)}/>  
        </Tooltip>
      </BottomNavigation>
    )

    const menuList = (
      <div style={{width: 300}}>
        <div className="cell hide-for-small user-info padding-distributed medium-center small-center white-bg logo-banner">
          <div className="cell center">
            <img className="user-image" src={efxLogo} width="50" alt="efx logo"/>
          </div>
          <div className="cell center f-15">{user.name} {user.surname}</div>
          <div className="cell center f-12">{user.email}</div>
          
        </div>

        {isAuthenticated ? userControlLinks: ''}

        <div className="padding-all" id="sideMenu">
          <ul className="grid-x grid-margin-x menu icons icon-top f-14 user-menu padding-top">
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/dashboard">
                <img src={options} alt="option-icon"/>
                <span>STATUS</span>
              </Link>
            </li> 
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/clients-management">
                <img src={clients} alt="clients-icon"/>
                <span>CLIENTS</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/services-management">
                <img src={services} alt="services-icon"/>
                <span>SERVICES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/pages-management">
                <img src={slider} alt="pages-icon"/>
                <span>PAGES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/projects-management">
                <img src={slider} alt="projects-icon"/>
                <span>PROJECTS</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/slider-management">
                <img src={slider} alt="slider-icon"/>
                <span>SLIDER</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/invoices-management">
                <img src={invoice} alt="invoices-management"/>
                <span>INVOICES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/payments">
                <img src={payments} alt="payments-icon"/>
                <span>PAYMENTS</span>
              </Link>
              </li>
              <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/microcontrollers">
                <img src={chip} alt="controller-icon"/>
                <span>MCTR</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/files-management">
                <img src={chip} alt="controller-icon"/>
                <span>FILES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/events-management">
                <img src={chip} alt="calendar-icon"/>
                <span>EVENTS</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );

    return(
      <Drawer 
      open={left.open} 
      onClose={()=>this.clickToggler()}
      classes={{
        paper: "blue-bg-r",
      }}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={()=>this.clickToggler()}
          onKeyDown={()=>this.clickToggler()}
        >

        <AppBar position="sticky" color="default">
          <Toolbar>
            <IconButton 
            fontSize="small" 
            color="default" 
            aria-label="Open 
            drawer" onClick={()=>this.clickToggler()} 
            style={{marginLeft: -12, marginRight: 20}}>
              <CloseMorph/>
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap style={{flexGrow: 1}}>
              EFX PRO Media
            </Typography>
          </Toolbar>
        </AppBar>

          {menuList}

        </div>
      </Drawer>
    )
  }

}

LeftDrawer.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  drawers: PropTypes.object, 
  toggleDrawerL: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  drawers: state.drawers
});

export default withRouter(connect(mapStateToProps, {logoutUser, toggleDrawerR, toggleDrawerL})(LeftDrawer)) ;