import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from "jquery";
//import _ from 'lodash';

//ACTIONS
import { getClient } from '../../../../actions/clientsActions';

// COMPONENT UTILS
import Spinner from '../../../../assets/images/ui/spinner.svg';
import UploadImg from '../../../../assets/images/ui/add-image-wide.png';
import DeleteAlert from '../../../Alerts/DeleteAlert';

//MATERIAL UI
import {
  Button,
  Tabs,
  Tab,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';

import {
  ArrowBackIos,
  Delete,
  Check
} from '@material-ui/icons';

class Client extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      alertRequestUrl: null,
      tabValue: 0,
      navigationValue: 'save',
      submitResponse: '',
      validForm: false,
      submitting: false,
      isVerified: false,
      clientId: '',
      name: '',
      surname: '',
      company: '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressPostCode: '',
      addressCountry: '',
      contactPhoneMobile: '',
      contactPhoneOptional: '',
      email: '',
      password: '',
      mainImage: '',
      dateCreated: '',
      sites: [],
      deleteMainImage: false,
      files: null,
      user: {}
    }
    // STANDARD COMPONENT FNs
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleBotNavigationChange = this.handleBotNavigationChange.bind(this);

    this.inputChange = this.inputChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.deleteUpload = this.deleteUpload.bind(this);
  }

  componentDidMount() {
    this.props.getClient(this.props.match.params.id);

    $(document).on("click", ".addSite", function (e) {
      e.preventDefault();
      var inputModel =
        '<input type="text" name="site" class="site" aria-errormessage="siteErr" placeholder="http://www.mysite.com" required pattern="url">';
      $("#sites").append(inputModel);
    });

    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = '';
    });// ALERT IF GOING TO REFRESH

  }// COMPONENT DID MOUNT

  componentWillReceiveProps(props) {
    const client = props.clients.client; // pass props to state
    this.setState({
      clientId: client._id,
      name: client.name,
      surname: client.surname,
      company: client.company,
      addressLine1: client.addressLine1,
      addressLine2: client.addressLine2,
      addressCity: client.addressCity,
      addressPostCode: client.addressPostCode,
      addressCountry: client.addressCountry,
      contactPhoneMobile: client.contactPhoneMobile,
      contactPhoneOptional: client.contactPhoneOptional,
      email: client.email,
      password: client.password,
      mainImage: client.profileImage,
      user: this.props.auth.user,
      profileImage: client.profileImage,
      dateCreated: client.dateCreated,
      sites: client.sites
    });

  }//COMPONENT WILL RECEIVE PROPS

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  handleBotNavigationChange = (event, navigationValue) => {
    this.setState({ navigationValue });
  };

  inputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  fileChange(e) {
    this.setState({ files: e.target.files[0] });
    setTimeout(() => {
      console.log(this.state.files);
      const uploadForm = document.getElementById("uploadFile"); // or document.forms
      uploadForm.dispatchEvent(new Event('submit'));
    }, 500);
  }

  uploadSubmit(e) {
    e.preventDefault();
    const form = document.getElementById("uploadFile"); // or document.forms
    const formData = new FormData(form);
    formData.fileUpload = this.state.files;
    console.log(formData);

    axios({
      method: 'post',
      url: '/clients/upload',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(response => {
      console.log(response);
      if (response.status === 201) {
        this.setState({
          mainImage: response.data.filePath,
          deleteMainImage: true
        });
      }
    }).catch(error => {
      console.log("Error Uploading", error);
    });

  }// FILE UPLOAD

  formSubmit(e) {
    e.preventDefault();

    setTimeout(() => {
      this.setState({
        submitting: true
      });
    }, 500);

    const form = document.getElementById("editForm"); // or document.forms
    let formData = new FormData(form);

    const values = {};
    for (let [key, value] of formData.entries()) {
      if (values[key]) {
        if (!(values[key] instanceof Array)) {
          values[key] = new Array(values[key]);
        }
        values[key].push(value);
      } else {
        values[key] = value;
      }
    }// RECYCLABLE PROCESS TO GET VALUES FROM FIELDS IN REACT


    var siteArr = [];

    $(".site").each(function () {
      var siteUrl = $(this).val();
      siteArr.push({ 'url': siteUrl });
    }); //building array of sites from inputs
    values["sites"] = JSON.stringify(siteArr);// PUSHING ARRAYS!

    //console.log("Form Values", values);

    const id = this.state.clientId;

    axios({
      method: 'put',
      url: `/clients/${id}`,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: values
    }).then(response => {
      //console.log(response);
      if (response.status === 200) {
        this.setState({
          submitResponse: "Clients Updated",
          validForm: true,
          submitting: false
        });
        setTimeout(() => {
          this.setState({
            submitResponse: ''
          });
        }, 1000);//
      }
    }).catch(error => {
      console.log(error);
      setTimeout(() => {
        this.setState({
          submitResponse: "Please review the form details before sending",
          validForm: false,
          submitting: false
        });
      }, 1000);
    });
  }// UPDATE FORM SUBMIT

  clickSubmit() {
    const form = document.getElementById("editForm"); // or document.forms
    form.dispatchEvent(new Event('submit'));
  }

  deleteUpload(file) {
    axios({
      method: 'post',
      url: '/file/delete',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: {
        filePath: file
      }
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        this.setState({
          mainImage: ''
        });
      }
    })
      .catch(error => {
        console.log(error);
      });

  }// DELETE FILE

  deleteItem(itemID) {
    console.log("Delete ID", itemID);
    axios({
      method: 'get',
      url: '/clients/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      //console.log(res.data);
      let client = res.data.client
      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete user?",
        alertContent: client.name + " " + client.surname,
        alertData: res.data.client,
        alertRequestUrl: `/clients/${client._id}`
      });
    }).catch(err => {
      console.log(err);
    });//axios
  }// DELETE ITEM

  render() {

    const {
      confirmAlertIsOpen,
      alertHeader,
      alertContent,
      alertData,
      alertRequestUrl,
      tabValue,
      navigationValue,
      validForm,
      submitResponse,
      deleteMainImage,
      submitting,
      clientId,
      name,
      surname,
      company,
      addressLine1,
      addressLine2,
      addressCity,
      addressPostCode,
      addressCountry,
      contactPhoneMobile,
      contactPhoneOptional,
      email,
      mainImage,
      user,
      dateCreated,
      sites,
    } = this.state;

    const { client, loading } = this.props.clients;

    let formConfirmation = (
       <div className={`cell large-12 strong ${validForm ? 'green' : 'red'}`} dangerouslySetInnerHTML={{ __html: this.state.submitResponse }}></div>
    )

    const mainInfo = (
      <div>
        <form className="grid-x" id="uploadFile" onSubmit={this.uploadSubmit.bind(this)} ref={upload => this.form = upload}>
          <div className="input-image-upload large-6 medium-6 small-12 cell">
            <div className="callout center">
              <label htmlFor="fileUpload" data-tooltip title="CLICK TO UPLOAD">
                <img id="image-1" src={mainImage ? mainImage : UploadImg} alt="upload" width="100%" />
                <span className="imageName">UPLOAD</span>
              </label>
              <input type="file" name="fileUpload" id="fileUpload" className="inputfile inputfile-2" onChange={this.fileChange} />
              {deleteMainImage ?
                <Button color="inherit" onClick={() => this.deleteUpload(mainImage)}>
                  REMOVE IMAGE
                    </Button> :
                ''}
            </div>
          </div>
        </form>


        <form data-abide noValidate id="editForm" onSubmit={this.formSubmit.bind(this)} encType="multipart/form-data" ref={el => this.form = el}>

          <label htmlFor="name">Image (URL)
                <input type="text" name="profileIMage" id="profileIMage" aria-errormessage="imageErr" required value={mainImage} onChange={this.inputChange} />
            <span className="form-error" id="imageErr">
              The client needs some image to be identified
                </span>
          </label>

          <label htmlFor="name">Name
                <input type="text" name="name" id="name" aria-errormessage="titleErr" value={name} onChange={this.inputChange} required />
            <span className="form-error" id="titleErr">
              How should you call them?
                </span>
          </label>

          <label htmlFor="surname">Surname
                <input type="text" name="surname" id="surname" aria-errormessage="surnameErr" value={surname} onChange={this.inputChange} required />
            <span className="form-error" id="titleErr">
              Unless he/she doesn't have a father or a mother.
                </span>
          </label>

          <label htmlFor="company">Company
                <input type="text" name="company" id="company" aria-errormessage="companyErr" value={company} onChange={this.inputChange} required />
            <span className="form-error" id="companyErr">
              You must enter a company here..
                </span>
          </label>


          <label htmlFor="addressLine1">Adress
                <input type="text" name="addressLine1" id="addressLine1" aria-errormessage="addrErr" value={addressLine1} onChange={this.inputChange} required />
            <span className="form-error" id="addrErr">
              I need this address..
                </span>
          </label>

          <label htmlFor="addressLine2">Address Optional
                <input type="text" name="addressLine2" id="addressLine2" aria-errormessage="addrOpErr" value={addressLine2} onChange={this.inputChange} />
            <span className="form-error" id="addrOpErr">
              In case you have another line...
                </span>
          </label>

          <label htmlFor="addressCity">City
                <input type="text" name="addressCity" id="addressCity" aria-errormessage="cityErr" value={addressCity} onChange={this.inputChange} required />
            <span className="form-error" id="cityErr">
              Need a city!
                </span>
          </label>

          <label htmlFor="addressPostCode">Post Code
                <input type="text" name="addressPostCode" id="addressPostCode" aria-errormessage="postCodeErr" value={addressPostCode} onChange={this.inputChange} required />
            <span className="form-error" id="postCodeErr">
              Need a valid Postcode
                </span>
          </label>

          <label htmlFor="addressCountry">Country
                <select name="addressCountry" id="addressCountry" aria-errormessage="countryErr" onChange={this.inputChange} required>
              <option value={addressCountry}> {addressCountry} </option>
              <option value="CA">Canada</option>
              <option value="UK">United Kingdom</option>
            </select>
            <span className="form-error" id="countryErr">
              Every man needs a country!
                </span>
          </label>

          <label htmlFor="contactPhoneMobile">Mobile
                <input type="text" name="contactPhoneMobile" id="contactPhoneMobile" aria-errormessage="mobileErr" value={contactPhoneMobile} onChange={this.inputChange} required />
            <span className="form-error" id="mobileErr">
              Everybody has one!
                </span>
          </label>

          <label htmlFor="contactPhoneOptional">Landline (Optional)
                <input type="text" name="contactPhoneOptional" id="contactPhoneOptional" aria-errormessage="mobileOpErr" value={contactPhoneOptional} onChange={this.inputChange} />
            <span className="form-error" id="mobileOpErr">
              Additional Contact
                </span>
          </label>

          <label htmlFor="dateCrated">Start Date
                <input type="date" name="dateCrated" id="dateCrated" aria-errormessage="mobileOpErr" value={dateCreated} onChange={this.inputChange} />
            <span className="form-error" id="mobileOpErr">
              We started something one day!
                </span>
          </label>

          <label htmlFor="email">Email
                <input type="email" name="email" id="email" aria-errormessage="emailErr" value={email} onChange={this.inputChange} required />
            <span className="form-error" id="emailErr">
              This doe snot appear to be a valid email
                </span>
          </label>

          <label htmlFor="password"> New Password
                <input type="password" name="password" id="password" aria-errormessage="passwordErr" onChange={this.inputChange} required />
            <span className="form-error" id="passwordErr">
              Enter a password
                </span>
          </label>

          <label htmlFor="passwordConfirm">Confirm New Password
                <input type="password" name="passwordConfirm" id="passwordConfirm" aria-errormessage="passwordConfErr" data-equalto="password" onChange={this.inputChange} required />
            <span className="form-error" id="passwordConfErr">
              Your passwords don't match, Please make sure it is the same.
                </span>
          </label>

          <label htmlFor="dateCreated">Created By {user.name} {user.surname}
            <input type="text" name="createdBy" id="createdBy" aria-errormessage="dateCreatedErr" value={user.userId} readOnly />
            <span className="form-error" id="createdByErr">
              Who Created it?
              </span>
          </label>

          {formConfirmation}

        </form>

        <div className="cell large-6 margin-top-2x">
          <div id="sites">
            <label htmlFor="site">Sites
                  {sites ? sites.map(site =>
              <input type="text" name="site" className="site" aria-errormessage="siteErr" value={site.url} required pattern="url" />
            ) : ""}
              <span className="form-error" id="siteErr">
                Yo, you had better fill this out, it's required.
                  </span>
            </label>
          </div>
          <button className="button tiny hollow addSite" >ADD SITE</button>
        </div>

      </div>
    );// FORM DETAILS CONTENT

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    let componentData;

    if (client) {
      componentData = (
        <div className="grid-x gray_1-bg">
          <div className="cell auto shadow_1 padding-all">
            <Link to="/clients-management">
              <Button color="primary">
                <ArrowBackIos />  CLIENTS
              </Button>
            </Link>
          </div>
          <div className="cell padding-bottom-2x">
            <div className="grid-x grid-margin-x white-bg padding-all" data-equalizer-watch="header">
              <div className="large-12 medium-12 small-12 cell ">
                <img src={client.profileImage} width="100" alt={name} />
                <h3>{client.name} {client.surname}</h3>
                <div className="f-12 gray_3 margin-bottom"> Customer since {dateCreated} </div>
              </div>
              <div className="cell auto gray_1-bg round-medium-top">
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className="gray_2-border-bottom round-medium-top"
                >
                  <Tab label="Main Info" />
                  <Tab label="Invoices" />
                  <Tab label="Contracts" />
                </Tabs>
                {tabValue === 0 && <div className="padding-all-2x small-padding-all gray_1-bg">
                  {mainInfo}
                  {submitResponse}
                </div>}
                {tabValue === 1 && <div className="padding-all-2x">DESCRIPTION MOVED</div>}
                {tabValue === 2 && <div className="padding-all-2x">Client</div>}
              </div>
            </div>
          </div>
          <div className="cell">
            {submitResponse}
            <BottomNavigation
              value={navigationValue}
              onChange={this.handleBotNavigationChange}
              showLabels
              className="cell shadow_1"
              style={{ position: "fixed", bottom: 0, flexGrow: 1 }}
            >
              <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
                content={alertContent}
                handleAlertClose={() => this.handleAlertClose()}
                alertHeader={alertHeader}
                alertRequestUrl={alertRequestUrl}
                alertData={alertData}
              />
              <BottomNavigationAction label="Delete" value="delete" icon={<Delete />} onClick={() => {this.deleteUpload(mainImage); this.deleteItem(clientId)}}/>
              <BottomNavigationAction label={submitting ? "Saving" : "Save"} value="save" onClick={() => this.clickSubmit()} icon={<Check />} />
            </BottomNavigation>
          </div>
        </div>);
    } else {
      componentData = (
        <div>No Project data to load</div>
      )
    }

    return (
      <div>
        {loading ? contentLoading : componentData}
      </div>
    )
  }
}

Client.propTypes = {
  getClient: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
});

export default connect(mapStateToProps, { getClient })(Client);
