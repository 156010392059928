import { GET_CLIENTS, GET_CLIENT, CLIENTS_LOADING, DELETE_CLIENTS } from '../actions/types';
 
const initialState = {
  clientsData: [],
  client: {},
  loading: false
}

//clients has to be an object because it contains the clients list and the count

export default function(state = initialState, action){
  switch(action.type){
    case GET_CLIENTS:
      return {
        ...state,
        clientsData: action.payload,
        loading: false
      };
      case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
      case DELETE_CLIENTS:
       return {
         ...state,
         clientsData: action.payload
       };
      case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
    return state;
  }
}