import axios from 'axios';
import { GET_PAGES, PAGES_LOADING } from './types'; //DELETE_SERVICES


// ### GET PAGES
export const getPages = () => dispatch => {

  dispatch(isLoading());

  axios({
    method: 'get',
    url:'/pages', 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_PAGES", res.data);

    dispatch({
      type: GET_PAGES,
      payload: res.data.pages
    });

  }).catch( err => {

    dispatch({
      type: GET_PAGES,
      payload: null
    });

  });//axios

}// PAGES DISPATCH GET_PAGES

export const isLoading = () => {
  return {
    type: PAGES_LOADING
  }
}


//### POST PAGES



//### DELETE PAGE

