import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER, SET_COMPONENT_INFO } from './types';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

// LOGIN
export const loginUser = (userData) => dispatch => {
  axios({
    method: 'post',
    url:'/users/login',
    data: userData  
  }).then( res => {
    //console.log(res.data);

    const {auth} = res.data; // "auth" from /user/login

    localStorage.setItem('auth', auth);
    setAuthToken(auth);

    const decoded = jwt_decode(auth);

    dispatch(setCurrentUser(decoded));

  }).catch( err => {
    console.log("login Error", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });

  });//axios
}

/// ADD MORE ACTIONS BELLOW AND CALL THEM USING THIS FILE {COSNTNAME}

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload:decoded
  }
}

//LOGOUT ACTIONS

export const logoutUser = () => dispatch => {
  //remove token from localStorage
  localStorage.removeItem('auth');

  setAuthToken(false);  //remove auth

  dispatch(setCurrentUser({
    isAuthenticated: false
  }));

  setTimeout(()=>{
    window.location = '/adminr-login';
  }, 1000);
  
}

//PAGE NAME CHANGE

export const pageAttributes = (data) => {
  return {
    type: SET_COMPONENT_INFO,
    payload: data
  }
}