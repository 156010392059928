import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import ReactQuill from 'react-quill';

//ACTIONS
import { getProject } from '../../../../actions/projectsActions';
import { getClients } from '../../../../actions/clientsActions';
import { getNotifications } from '../../../../actions/notificationsActions';

// COMPONENT UTILS
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../../../TextEditor/TextEditorConfig';
import Spinner from '../../../../assets/images/ui/spinner.svg';
import UploadImg from '../../../../assets/images/ui/add-image-wide.png';
import DeleteAlert from '../../../Alerts/DeleteAlert';


//MATERIAL UI
import {
  Button,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';

import {
  ArrowBackIos,
  Delete,
  Check
} from '@material-ui/icons';

class Project extends Component {
  constructor() {
    super();
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      alertRequestUrl: null,
      deleteMainImage: false,
      files: null,
      tabValue: 0,
      navigationValue: 'save',
      submitResponse: '',
      validForm: false,
      submitting: false,
      isVerified: false,
      isActive: null,
      projectId: '',
      name: '',
      logoImage: '',
      mainImage: '',
      projectUrl: '',
      liveUrl: '',
      status: '',
      createdBy: '',
      dateCreated: '',
      businessType: '',
      projectType: '',
      description: '',
      isChecked: '',
    }
    this.inputChange = this.inputChange.bind(this);
    this.switchChange = this.switchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleBotNavigationChange = this.handleBotNavigationChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.clickSubmit = this.clickSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getProject(this.props.match.params.id);
    this.props.getClients();
    this.props.getNotifications();

    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = '';
    });// ALERT IF GOING TO REFRESH
  }

  componentWillReceiveProps(nextProps) {

    const project = nextProps.projects.project; // pass props to state
    const user = this.props.auth.user.userId;
    const projectTypeArr = _.map(project.projectType, "item").join(', ');

    project.isChecked = project.isActive ? project.isActive : false;

    this.setState({
      projectId: project._id,
      name: project.name,
      logoImage: project.logoImage,
      mainImage: project.mainImage,
      projectUrl: project.projectUrl,
      liveUrl: project.liveUrl,
      status: project.status,
      dateCreated: project.dateCreated,
      businessType: project.businessType,
      projectType: projectTypeArr,
      isChecked: project.isChecked,
      description: project.description,
      createdBy: user,
    });

  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  handleBotNavigationChange = (event, navigationValue) => {
    this.setState({ navigationValue });
  };

  handleChange(e) {
    this.setState({ description: e });
  }

  switchChange() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  inputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  fileChange(e) {
    this.setState({ files: e.target.files[0] });
    setTimeout(() => {
      console.log(this.state.files);
      const uploadForm = document.getElementById("uploadFile"); // or document.forms
      uploadForm.dispatchEvent(new Event('submit'));
    }, 500);
  }

  uploadSubmit(e) {
    e.preventDefault();
    const form = document.getElementById("uploadFile"); // or document.forms
    const formData = new FormData(form);
    formData.fileUpload = this.state.files;
    console.log(formData);

    axios({
      method: 'post',
      url: '/clients/upload',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(response => {
      console.log(response);
      if (response.status === 201) {
        this.setState({
          mainImage: response.data.filePath,
          deleteMainImage: true
        });
      }
    }).catch(error => {
      console.log("Error Uploading", error);
    });

  }// FILE UPLOAD

  formSubmit(e) {
    e.preventDefault();

    setTimeout(() => {
      this.setState({
        submitting: true
      });
    }, 500);

    const form = document.getElementById("editForm"); // or document.forms
    let formData = new FormData(form);

    const values = {};
    for (let [key, value] of formData.entries()) {
      if (values[key]) {
        if (!(values[key] instanceof Array)) {
          values[key] = new Array(values[key]);
        }
        values[key].push(value);
      } else {
        values[key] = value;
      }
    }// RECYCLABLE PROCESS TO GET VALUES FROM FIELDS IN REACT

    let types = this.state.projectType;
    let typesArr = [];
    let strArr = types.split(",");

    _.forEach(strArr, (value, key) => {
      let items = { "item": value };
      typesArr.push(items);
    });

    values.description = this.state.description;
    values.projectType = typesArr;
    values.isActive = this.state.isChecked;

    console.log("Form Values", values);

    const id = this.state.projectId;

    axios({
      method: 'put',
      url: `/projects/${id}`,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: values
    }).then(response => {
      //console.log(response);
      if (response.status === 200) {
        this.setState({
          submitResponse: "Project Updated",
          validForm: true
        });
        setTimeout(() => {
          this.setState({
            submitResponse: '',
            submitting: false
          });
        }, 2000);//
      }
    }).then(()=>{
      
    }).catch(error => {
      console.log(error);
      setTimeout(() => {
        this.setState({
          submitResponse: "Please review the form details before sending",
          validForm: false
        });
      }, 1000);
    });
  }// UPDATE FORM SUBMIT

  clickSubmit() {
    const form = document.getElementById("editForm"); // or document.forms
    form.dispatchEvent(new Event('submit'));
  }//CLICK SEND FORM

  deleteUpload(file) {
    axios({
      method: 'post',
      url: '/file/delete',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth'),
        'Content-Type': 'application/json'
      },
      data: {
        filePath: file
      }
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        this.setState({
          mainImage: ''
        });
      }
    })
      .catch(error => {
        console.log(error);
      });

  }// DELETE FILE

  deleteItem(itemID) {
    console.log("Delete ID", itemID);
    axios({
      method: 'get',
      url: '/projects/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      //console.log(res.data);
      let item = res.data.project
      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete Project?",
        alertContent: item.name,
        alertData: res.data.project,
        alertRequestUrl: `/projects/${item._id}`
      });
    }).catch(err => {
      console.log(err);
    });//axios
  }// DELETE ITEM

  render() {

    const {
      confirmAlertIsOpen,
      alertHeader,
      alertContent,
      alertData,
      alertRequestUrl,
      deleteMainImage,
      tabValue,
      navigationValue,
      isChecked,
      projectId,
      name,
      logoImage,
      mainImage,
      projectUrl,
      liveUrl,
      status,
      createdBy,
      dateCreated,
      businessType,
      projectType,
      description,
      submitResponse,
      submitting
    } = this.state;

    const { project, loading } = this.props.projects;
    const { clientsData } = this.props.clients;

    const mainInfo = (
      <div>

        <form className="grid-x" id="uploadFile" onSubmit={this.uploadSubmit.bind(this)} ref={upload => this.form = upload}>
          <div className="input-image-upload large-6 medium-6 small-12 cell">
            <div className="callout center">
              <label htmlFor="fileUpload" data-tooltip title="CLICK TO UPLOAD">
                <img id="image-1" src={mainImage ? mainImage : UploadImg} alt="upload" width="100%" />
                <span className="imageName">UPLOAD</span>
              </label>
              <input type="file" name="fileUpload" id="fileUpload" className="inputfile inputfile-2" onChange={this.fileChange} />
              {deleteMainImage ?
                <Button color="inherit" onClick={() => this.deleteUpload(mainImage)}>
                  REMOVE IMAGE
                    </Button> :
                ''}
            </div>
          </div>
        </form>

        <form data-abide className="grid-x" noValidate id="editForm" onSubmit={this.formSubmit.bind(this)} ref={el => this.form = el}>


          <div className="cell large-12 medium-12">

            <FormControlLabel
              control={
                <Switch
                  onChange={this.switchChange}
                  checked={isChecked}
                />
              }
              label="Is Active"
            />

            <label htmlFor="name" >Main Image URL
            <input type="text" name="mainImage" id="mainImage" aria-errormessage="titleErr" required value={mainImage} onChange={this.inputChange} />
              <span className="form-error" id="titleErr">
                How should you call this?
            </span>
            </label>

            <label htmlFor="name" >Name
            <input type="text" name="name" id="name" aria-errormessage="titleErr" required value={name} onChange={this.inputChange} />
              <span className="form-error" id="titleErr">
                How should you call this?
            </span>
            </label>

            <label htmlFor="client">Client
            <select name="client" id="client" aria-errormessage="clientErr" required onChange={this.inputChange}>
                <option value={project.client ? project.client._id : ""}>
                  - {project.client ? project.client.name : ""} {project.client ? project.client.surname : ""} -
              </option>

                {clientsData.map((client) =>
                  <option key={client._id} value={client._id}> {client.name} {client.surname}</option>
                )}
              </select>
              <span className="form-error" id="clientListErr">
                Its good to know who wanted it!
            </span>
            </label>

            <label htmlFor="logoImage">Project Logo
          <img src={logoImage} width="50" alt={name} />
              <input type="text" name="logoImage" id="logoImage" aria-errormessage="logoImageErr" value={logoImage} required onChange={this.inputChange} />
              <span className="form-error" id="logoImageErr">
                Unless he/she doesn't have a father or a mother.
          </span>
            </label>

            <label htmlFor="dateCreated">Created By
          <input type="text" name="createdBy" id="createdBy" aria-errormessage="dateCreatedErr" value={createdBy} readOnly onChange={this.inputChange} />
              <span className="form-error" id="createdByErr">
                Who Created it?
          </span>
            </label>

            <label htmlFor="dateCreated">Created Date
          <input type="date" name="dateCreated" id="dateCreated" aria-errormessage="dateCreatedErr" value={dateCreated} required onChange={this.inputChange} />
              <span className="form-error" id="dateCreatedErr">
                Need a date plzzz...
          </span>
            </label>

            <label htmlFor="businessType">Business Type
          <input type="text" name="businessType" id="businessType" aria-errormessage="businessTypeErr" value={businessType} required onChange={this.inputChange} />
              <span className="form-error" id="businessTypeErr">
                What business is it in?
          </span>
            </label>

            <label htmlFor="projectUrl">Internal Project URL
          <input type="text" name="projectUrl" id="projectUrl" aria-errormessage="projectUrlErr" value={projectUrl} onChange={this.inputChange} />
              <span className="form-error" id="projectUrlErr">
                If a dynamic content is available trow it here.
          </span>
            </label>

            <label htmlFor="liveUrl">Live URL
          <input type="text" name="liveUrl" id="liveUrl" aria-errormessage="liveUrlErr" value={liveUrl} onChange={this.inputChange} />
              <span className="form-error" id="liveUrlErr">
                Where do I see it live!
          </span>
            </label>

            <label htmlFor="status">Status
          <select name="status" id="status" aria-errormessage="statusErr" value={status} required onChange={this.inputChange}>
                <option value="live">Live</option>
                <option value="archived">Archived</option>
              </select>
              <span className="form-error" id="statusErr">
                Its good to know who wanted it!
          </span>
            </label>

          </div>
        </form>

        <div id="types">
          <label htmlFor="projectType">Project Type (Spareted by comma)
            <input type="text" name="projectType" id="projectType" aria-errormessage="projectTypeErr" value={projectType} required onChange={this.inputChange} />
            <span className="form-error" id="projectTypeErr">
              Unless he/she doesn't have a father or a mother.
            </span>
          </label>
        </div>
        <label htmlFor="description">Project Description</label>
        <ReactQuill
          value={description}
          placeholder="Enter your page Content"
          onChange={this.handleChange}
          theme="snow"
          modules={modules}
          formats={formats} />
      </div>
    );// FORM DETAILS CONTENT

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    let componentData;

    if (project) {
      componentData = (
        <div className="grid-x gray_1-bg">
          <div className="cell auto shadow_1 padding-all">
            <Link to="/projects-management">
              <Button color="primary">
                <ArrowBackIos />  PROJECTS
              </Button>
            </Link>
          </div>
          <div className="cell padding-bottom-2x">
            <div className="grid-x grid-margin-x white-bg padding-all" data-equalizer-watch="header">
              <div className="large-12 medium-12 small-12 cell ">
                <img src={project.logoImage} width="100" alt={name} />
                <h3>{project.name}</h3>
                <div className="f-12 gray_3 margin-bottom"> {project.liveUrl}</div>
              </div>
              <div className="cell auto gray_1-bg round-medium-top">
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className="gray_2-border-bottom round-medium-top"
                >
                  <Tab label="Main Info" />
                  <Tab label="Description" />
                  <Tab label="Client" />
                </Tabs>
                {tabValue === 0 && <div className="padding-all-2x small-padding-all gray_1-bg">
                  {mainInfo}
                  {submitResponse}
                </div>}
                {tabValue === 1 && <div className="padding-all-2x">DESCRIPTION MOVED</div>}
                {tabValue === 2 && <div className="padding-all-2x">Client</div>}
              </div>
            </div>
          </div>
          <div className="cell">
            {submitResponse}
            <BottomNavigation
              value={navigationValue}
              onChange={this.handleBotNavigationChange}
              showLabels
              className="cell shadow_1"
              style={{ position: "fixed", bottom: 0, flexGrow: 1 }}
            >

            <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
                content={alertContent}
                handleAlertClose={() => this.handleAlertClose()}
                alertHeader={alertHeader}
                alertRequestUrl={alertRequestUrl}
                alertData={alertData}
              />

              <BottomNavigationAction label="Delete" value="delete" icon={<Delete />} onClick={() => { this.deleteUpload(mainImage); this.deleteItem(projectId) }} />
              <BottomNavigationAction label={submitting ? "Saving" : "Save"} value="save" onClick={() => this.clickSubmit()} icon={<Check />} />
            </BottomNavigation>
          </div>
        </div>);
    } else {
      componentData = (
        <div>No Project data to load</div>
      )
    }

    return (
      <div>
        {loading ? contentLoading : componentData}
      </div>
    )
  }
}

Project.propTypes = {
  getProject: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  getNotifications:PropTypes.func,
  getClients: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  clientsData: PropTypes.array
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
  projects: state.projects,
  notifications: state.notifications
});

export default connect(mapStateToProps, { getNotifications, getProject, getClients })(Project);
