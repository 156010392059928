import { GET_PROJECTS, GET_PROJECT, PROJECTS_LOADING, DELETE_PROJECT } from '../actions/types';
 
const initialState = {
  projectsData: [],
  project: {},
  loading: false
}

//services has to be an object because it contains the services list and the count

export default function(state = initialState, action){
  switch(action.type){
    case GET_PROJECT:
    return{
      ...state,
      project: action.payload,
      loading: false
    };
    case GET_PROJECTS:
      return {
        ...state,
        projectsData: action.payload,
        loading: false
      };
      case DELETE_PROJECT:
       return {
         ...state,
         projectsData: action.payload
       };
      case PROJECTS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
    return state;
  }
}