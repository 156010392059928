import { 
  SET_LEFT_DRAWER_OPEN, 
  SET_LEFT_DRAWER_CLOSED, 
  SET_TOGGLE_L_DRAWER,
  SET_TOGGLE_R_DRAWER 
} from '../actions/types';
 
const initialState = {
  left: {
    open: false
  },
  right: {
    open: false
  }
  
}

export default function(state = initialState, action){
  switch(action.type){
    case SET_LEFT_DRAWER_OPEN:
      return {
        ...state,
        left:{open: true}
      };
    case SET_LEFT_DRAWER_CLOSED:
      return {
        ...state,
        left:{ open: false }
      };
      case SET_TOGGLE_L_DRAWER:
      return {
        ...state,
        left: {
          open: action.payload.open
        }
      };
      case SET_TOGGLE_R_DRAWER:
      return {
        ...state,
        right: {
          open: action.payload.open
        }
      };
    default:
    return state;
  }
}