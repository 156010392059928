import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
// import { Link } from "react-router-dom";

import { withRouter } from "react-router";

import { toggleDrawerR } from '../../actions/drawerActions';

import Notifications from '../Pages/Notifications/Notifications';
import './drawer.scss';


import {
  Drawer,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  BottomNavigationAction,
  Tooltip,
  Badge
} from '@material-ui/core';

import {
  Settings,
  ExitToApp,
  NotificationsActive,
  SupervisedUserCircle,
  Satellite
} from '@material-ui/icons';

class RightDrawer extends Component {

  constructor() {
    super();
    this.state = {
      tabValue: 0
    }
    this.handleTabChange = this.handleTabChange.bind(this);
  }
  componentDidMount(){
   
      if(this.props.auth.isAuthenticated){
        this.props.toggleDrawerR(this.props.drawers.right.open);
      }
 
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }//logs out the user

  clickToggler() {
    this.props.toggleDrawerR(this.props.drawers.right.open);
  }

  linkToLocation(url) {
    this.props.history.push(url);
  }

  render() {

    const {isAuthenticated} = this.props.auth;
    const { tabValue } = this.state;
    const { right } = this.props.drawers;

    const userControlLinks = (
      <AppBar position="fixed" color="default" style={{top: 'auto', bottom: 0}}>
        <Toolbar>
        <Tooltip title="Settings" placement="top">
          <BottomNavigationAction onClick={() => this.linkToLocation("/settings")} label="Settings" icon={<Settings />} />
        </Tooltip>
        <Tooltip title="Users" placement="top">
          <BottomNavigationAction onClick={() => this.linkToLocation("/admin-users-management")} label="Users" icon={<SupervisedUserCircle />} />
        </Tooltip>
        </Toolbar>
      </AppBar>
    )

    const drawer = (
      <Drawer
        open={right.open}
        onClose={() => this.clickToggler()}
        variant="persistent"
        anchor="right"
        className={right.open? "drawerFix blue-bg-r hide-for-small-only":""}
        classes={{
          paper: "blue-bg-r",
        }}
        styles={{borderLeft: "none"}}
      >
        <div className="cell" style={{maxWidth:300}}>
          <AppBar position="sticky" color="default" >
              <Tabs
                value={tabValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
                centered
              >
                <Tab label={
                  <Badge color="secondary" badgeContent={this.props.notifications.itemsCount}>
                    <NotificationsActive />
                  </Badge>
                }/>
                <Tab icon={<SupervisedUserCircle />} />
              </Tabs>
         
          </AppBar>

          <div>
            <div>
              {tabValue === 0 && <div className="padding-all-2x cell"><Notifications /></div>}
              {tabValue === 1 && <div className="padding-all-2x cell">DESCRIPTION MOVED DESCRIPTION MOVED</div>}
            </div>
          </div>
          {userControlLinks}
        </div>
      </Drawer>
    );

    return (
      <div>
        {isAuthenticated ? drawer : "" }
      </div>
    )
  }

}

RightDrawer.propTypes = {
  auth: PropTypes.object.isRequired,
  drawers: PropTypes.object,
  toggleDrawerR: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  drawers: state.drawers,
  auth: state.auth,
  notifications: state.notifications
});

export default withRouter(connect(mapStateToProps, { logoutUser, toggleDrawerR })(RightDrawer));