import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import axios from 'axios';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  IconButton,
  ListItemSecondaryAction
} from '@material-ui/core';

import Spinner from '../../../assets/images/ui/spinner.svg';

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      loading: false
    }
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    axios({
      method: 'get',
      url: '/clients',
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      this.setState({
        clients: res.data.clients,
        loading: false
      })
    }).catch(err => {
      console.log(err);
    });//axios

  }// componentDidMount


  render() {
    const { loading, clients } = this.state;
    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);
    const contentRender = (
      <List dense>
        {clients.map((client) =>
          <ListItem alignItems="flex-start" key={client._id} button>
            <ListItemAvatar>
              <Avatar className="margin-all" style={{ backgroundColor: "primary" }}>
                {client.name[0]}{client.surname[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography component="span" color="textPrimary">
                  {client.name} {client.surname}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography component="span" color="textSecondary">
                    {client.company}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton aria-label="Mail">
                <i className="fa fa-envelope"></i>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    )

    return (
      <div>
        {loading ? contentLoading : contentRender}
      </div>
    )
  }
}

Clients.proptypes = {
  auth: PropTypes.object.isrequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Clients);