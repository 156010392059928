import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { pageAttributes } from '../../../actions/authActions';
import { getServices } from '../../../actions/servicesActions';
import axios from 'axios';
import Spinner from '../../../assets/images/ui/spinner.svg';

import AddItemFullDialog from './addItemFullDialog';
import DeleteAlert from '../../Alerts/DeleteAlert';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  AppBar,
  IconButton
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class ServicesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      dialogIsOpen: false,
    }
    this.inputChange = this.inputChange.bind(this);
  }// CONSTRUCTOR

  componentDidMount() {
    this.props.getServices();
    const page = {
      name: "Services"
    };// include theme changes in this object

    this.props.pageAttributes(page);
  }//COMPONENT DID MOUNT

  inputChange(e) {
    this.setState({ 
      search: e.target.value,
      clients: _.filter(this.state.clients, {name: this.state.search})
    });
    console.log(this.state.search);
  }

  handleClickOpen() {
    this.setState({ dialogIsOpen: true });
  };

  handleClose() {
    this.setState({ dialogIsOpen: false });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  deleteItem(itemID) {
    console.log(itemID);

    axios({
      method: 'get',
      url: '/services/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      console.log(res.data);

      let service = res.data.service

      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete Service?",
        alertContent: service.name,
        alertData: res.data.service
      });
    }).catch(err => {
      console.log(err);
    });//axios

  }// DELETE


  render() {

    const theme = createMuiTheme({
      palette: {
        primary: {
          light: '#757ce8',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000'
        }
      },
      typography: {
        useNextVariants: true,
      }
    });

    const { dialogIsOpen, confirmAlertIsOpen, alertHeader, alertContent, alertData } = this.state;

    const { servicesData, loading } = this.props.services;

    console.log(this.props);

    const list = Array.from(servicesData);// turn it into array

    const itemsCount = servicesData.length;

    let servicesList;

    if (servicesData) {
      servicesList = (
        <TableBody>
          {list.map((service) =>
            <TableRow key={service._id}>
              <TableCell component="th" scope="row"> {service.name}  </TableCell>
              <TableCell>{service.description}</TableCell>
              <TableCell>
                <IconButton color="primary"><i className="fa fa-pen" style={{ fontSize: 15 }}></i></IconButton>
              </TableCell>
              <TableCell>
                <IconButton color="secondary" aria-label="Add Client" onClick={() => this.deleteItem(service._id)}>
                  <i className="fa fa-window-close" style={{ fontSize: 15 }}></i>
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      );//
    } else {
      servicesList = (
        <div> No Services Loaded</div>
      )
    }

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    return (
      <MuiThemeProvider theme={theme}>
        <div className="grid-x grid-margin-x grid-padding-x">

          <div className="large-12 medium-12 small-12 cell margin-top-2x">
            <Paper>
              <AppBar position="static" color="default" className="padding-distributed">
                <div className="grid-x grid-margin-x grid-padding-x">

                  <div className="cell large-3 medium-4 small-4 white counter">
                    <Typography variant="h6">
                      {itemsCount} SERVICES
                   </Typography>
                  </div>
                  <div className="cell large-6 medium-6 small-6">
                    <input type="text" name="search" className="search-MU round-small" placeholder="Search" onChange={this.inputChange} />
                  </div>
                  <div className="cell large-3 medium-2 small-2 right">
                    <Button color="primary" variant="contained" onClick={this.handleClickOpen.bind(this)}>
                      + SERVICE
                     </Button>
                  </div>
                </div>
              </AppBar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>EDIT</TableCell>
                    <TableCell>DELETE</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? "" : servicesList}
              </Table>
            </Paper>
            {loading ? contentLoading : ""}
          </div>

          <AddItemFullDialog dialogIsOpen={dialogIsOpen} handleClose={() => this.handleClose()} />
          <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
            content={alertContent}
            handleAlertClose={() => this.handleAlertClose()}
            alertHeader={alertHeader}
            alertData={alertData}
          />

        </div>
      </MuiThemeProvider>
    )
  }
}

ServicesManagement.propTypes = {
  auth: PropTypes.object.isRequired,
  pageAttributes: PropTypes.func.isRequired,
  getServices: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  servicesData: PropTypes.object
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
  services: state.services
});

export default connect(mapStateToProps, { getServices, pageAttributes })(ServicesManagement);