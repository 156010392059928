import axios from 'axios';
import { GET_SERVICES, SERVICES_LOADING } from './types'; //DELETE_SERVICES


// ### GET SERVICES
export const getServices = () => dispatch => {

  dispatch(isLoading());

  axios({
    method: 'get',
    url:'/services', 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_SERVICES", res.data);

    dispatch({
      type: GET_SERVICES,
      payload: res.data.services
    });

  }).catch( err => {

    dispatch({
      type: GET_SERVICES,
      payload: null
    });

  });//axios

}// SERVICES DISPATCH GET_SERVICES

export const isLoading = () => {
  return {
    type: SERVICES_LOADING
  }
}


//### POST SERVICES



//### DELETE SERVICES

