export const GET_ERRORS = 'GET_ERRORS';
export const USER_AUTHENTICATION = 'USER_AUTHENTICATION';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_COMPONENT_INFO = 'SET_COMPONENT_INFO';
export const SET_LEFT_DRAWER_OPEN = "SET_LEFT_DRAWER_OPEN";
export const SET_LEFT_DRAWER_CLOSED = "SET_LEFT_DRAWER_CLOSED";
export const SET_TOGGLE_L_DRAWER = "SET_TOGGLE_L_DRAWER";
export const SET_RIGHT_DRAWER_OPEN = "SET_LEFT_DRAWER_OPEN";
export const SET_RIGHT_DRAWER_CLOSED = "SET_LEFT_DRAWER_CLOSED";
export const SET_TOGGLE_R_DRAWER = "SET_TOGGLE_R_DRAWER";

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const DELETE_CLIENTS = 'DELETE_CLIENTS';
export const CLIENTS_LOADING = 'CLIENTS_LOADING';

export const GET_SERVICES = 'GET_SERVICES';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const SERVICES_LOADING = 'SERVICES_LOADING';

export const GET_PAGES = 'GET_PAGES';
export const DELETE_PAGE = 'DELETE_PAGES';
export const PAGES_LOADING = 'PAGES_LOADING';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECTS';
export const PROJECTS_LOADING = 'PROJECTS_LOADING';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_PROJECTS';
export const NOTIFICATIONS_LOADING = 'PROJECTS_LOADING';