import { GET_NOTIFICATIONS, DELETE_NOTIFICATION, NOTIFICATIONS_LOADING } from '../actions/types';
import _ from 'lodash';

const initialState = {
  notificationsList: [],
  itemsCount:'',
  loading: false
}

//clients has to be an object because it contains the clients list and the count

export default function(state = initialState, action){
  switch(action.type){
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsList: action.payload.notifications,
        itemsCount: action.payload.count,
        loading: false
      };
      case NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true
      };
      case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationsList: _.reject(state.notificationsList, { _id: action.payload })
      };
    default:
    return state;
  }
}