import {combineReducers} from 'redux';

import errorReducer from './errorReducer';
import authReducer from './authReducer';

// PAGES REDUCERS
import clientsReducer from './clientsReducer';
import servicesReducer from './servicesReducer';
import pagesReducer from './pagesReducer';
import projectsReducer from './projectsReducer';
import notificationsReducer from './notificationsReducer';

//COMPONENT REDUCERS
import drawerReducer from './drawerReducer';


export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  clients: clientsReducer,
  services: servicesReducer,
  pages: pagesReducer,
  drawers: drawerReducer,
  projects: projectsReducer,
  notifications: notificationsReducer
});