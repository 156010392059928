import React, { Component } from 'react';

class InvoicesManagement extends Component{
  render(){
    return(
      <div>Invoices</div>  
    )
  }
}

export default InvoicesManagement;