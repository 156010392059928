import axios from 'axios';
import { 
  GET_NOTIFICATIONS, 
  DELETE_NOTIFICATION, 
  NOTIFICATIONS_LOADING 
} from './types';

// ### GET NOTIFICATIONS
export const getNotifications = () => dispatch => {
  dispatch(isLoading());
  axios({
    method: 'get',
    url:'/notifications', 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_NOTIFICATIONS", res.data);
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data
    });
  }).catch( err => {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: null
    });
  });//axios
};

// LOADING EVENT
export const isLoading = () => {
  return {
    type: NOTIFICATIONS_LOADING
  }
};

//### DELETE NOTIFICATIONS
export const deleteNotification = (id) => dispatch => {

  axios({
    method: 'delete',
    url: `/notifications/${id}`,
    headers: {
      'Authorization': "Bearer " + localStorage.getItem('auth')
    }
  }).then(res => {

    dispatch({
      type: DELETE_NOTIFICATION,
      payload:  id
    });

    //console.log("Notification deleted", this.state.notifications);

  }).catch(err => {
    console.log(err);
  });//axios

};
