import React, { Component } from 'react';
import './404.scss'
import {Link} from 'react-router-dom';

import $ from "jquery";

class NotFound extends Component{
  // constructor(props){
  //   super(props)
  
  // }

  componentDidMount(){
    var originalBG = $(".full-section").css("background-color");

    $('.full-section').mousemove(function (e) {
      var x = e.pageX - this.offsetLeft;
      var y = e.pageY - this.offsetTop;
      var xy = x + " " + y;

      var lightColor = "#fff";
      var gradientSize = "500px";

      var bgWebKit = "-webkit-gradient(radial, " + xy + ", 0, " + xy + ", 100, from(rgba(255,255,255,0.9)), to(rgba(255,255,255,0.0))), " + originalBG;
      var bgMoz = "-moz-radial-gradient(" + x + "px " + y + "px 45deg, circle, " + lightColor + " 0%, " + originalBG + " " + gradientSize + "px)";

      $(this).css({
        background: bgWebKit
      }).css({
        background: bgMoz
      });

    }).mouseleave(function () {
      $(this).css({
        background: originalBG
      });
    });
  }// COMPONENT DID MOUNT
  
render(){

  return(
    <section className="full-section black-bg" id="NotFoundContainer">
        <div className="cell center" id="login">
        <div className="glitch" data-text="404">404</div>
        <div className=" f-16 white">THERE'S NOTHING HERE... RETURN TO ADMIN?</div>
        <Link to="/dashboard" className="button secondary hollow small margin-top round-medium">YES</Link>
      </div>
    </section>
  )
}

}

export default NotFound;

