import axios from 'axios';
import { GET_PROJECTS, GET_PROJECT, PROJECTS_LOADING } from './types'; //DELETE_SERVICES


// ### GET PROJECTS
export const getProjects = () => dispatch => {
  dispatch(isLoading());
  axios({
    method: 'get',
    url:'/projects', 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_PROJECTS", res.data); 
    dispatch({
      type: GET_PROJECTS,
      payload: res.data.projects
    });
  }).catch( err => {
    dispatch({
      type: GET_PROJECTS,
      payload: null
    });
  });//axios

}// PROJECTS DISPATCH GET_PROJECTS

export const getProject = (id) => dispatch => {

  dispatch(isLoading());

  axios({
    method: 'get',
    url:`/projects/${id}`, 
    headers: {
      'Authorization' : "Bearer " + localStorage.getItem('auth')
    }
  }).then( res => {
    console.log("GET_PROJECT", res.data);

    dispatch({
      type: GET_PROJECT,
      payload: res.data.project
    });

  }).catch( err => {

    dispatch({
      type: GET_PROJECT,
      payload: null
    });

  });//axios

}// PROJECTS DISPATCH GET_PROJECTS

export const isLoading = () => {
  return {
    type: PROJECTS_LOADING
  }
}


//### POST PROJECTS



//### DELETE PROJECTS

