import isEmpty from '../validation/is-empty'; 
import { SET_CURRENT_USER, SET_COMPONENT_INFO } from '../actions/types';
 

const initialState = {
  isAuthenticated: false,
  user: {},
  page:{}
}

export default function(state = initialState, action){
  switch(action.type){
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      }
    case SET_COMPONENT_INFO:
      return {
        ...state,
        page: action.payload
      }
    default:
    return state;
  }
}