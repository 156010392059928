import React, { Component } from 'react';
import axios from 'axios';

//MATERIAL UI 
import {
  Button, 
  DialogTitle,
  Dialog, 
  Slide, 
  DialogContent, 
  DialogActions,
} from '@material-ui/core';

class Alert extends Component{
  constructor(props){
    super(props);
    this.state = {
      performingAction: false
    }
  }

  confirmAction(){
    this.setState({
      performingAction: true
    });

    axios({
        method: 'delete',
        url: this.props.alertRequestUrl,
        headers: {
          'Authorization' : "Bearer " + localStorage.getItem('auth')
        }
      }).then( res => {
        console.log(res.data);
        this.setState({
          performingAction: false
        });
        this.props.handleAlertClose();
        
        setTimeout(()=>{
          window.location.reload();
        }, 1000);

      }).catch( err => {
        console.log(err);
      });//axios
  }

  render(){

    const {confirmAlertIsOpen, handleAlertClose, alertHeader} = this.props;

    let sending;

    if(this.state.performingAction){
      sending = "...";
    }else{
      sending = "CONFIRM";//change the send button
    }


    return(
      <Dialog open={this.props.confirmAlertIsOpen} onClose={handleAlertClose}>
        <DialogTitle id="responsive-dialog-title">{alertHeader}</DialogTitle>
      <Slide direction="up" in={confirmAlertIsOpen} mountOnEnter unmountOnExit>
      <DialogContent>
        {this.props.content}
      </DialogContent>
      </Slide>
      <DialogActions>
        <Button onClick={handleAlertClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={()=> this.confirmAction()} color="secondary" autoFocus>
          {sending}
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}

export default Alert;