import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { pageAttributes } from '../../../actions/authActions'; 

// DASHBOARD COMPONENTS
import Clients from './Clients';
import Events from './Events';
import Projects from './Projects';

import './dashboard.scss';
//import Calendar from './Calendar';

import {
  IconButton
  } from '@material-ui/core';

  import { MoreVert } from '@material-ui/icons';

class Dashboard extends Component{

  componentDidMount(){
    if(!this.props.auth.isAuthenticated){
      //window.location = '/adminr-login';
      this.props.history.push("/adminr-login");
    }
    const page = {
      name: "Dasboard"
    }
    this.props.pageAttributes(page);
  }

  render(){

    return(
      <div className="grid-x" data-equalizer="dashSections">
        <div className="cell large-12 medium-12 small-12 padding-all">
          <div className="grid-x grid-margin-x">

            <div className="large-6 medium-12 small-12 cell margin-distributed">
              <div className="card round-medium">
              <div className="grid-x grid-margin-x grid-padding-x padding-distributed blue-bg-r">
                <div className="cell large-9 medium-9 small-9 f-24 white">CLIENTS</div>
                <div className="cell large-3 medium-3 small-3 right">
                  <Link to="/clients-management" >
                    <IconButton aria-label="Mail">
                      <MoreVert className="white" fontSize="small"/> 
                    </IconButton>
                  </Link>
                </div>
                </div>
                <div className="card-section no-padding" data-equalizer-watch="dashSections">
                  <ul className="clientsList no-margin">
                    <Clients/>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="cell large-6 medium-12 small-12 margin-distributed">
              <div className="card round-medium">
                <div className="grid-x grid-margin-x grid-padding-x padding-distributed green-bg">
                  <div className="cell large-9 medium-9 small-9 f-24 white">AGENDA</div>
                  <div className="cell large-3 medium-3 small-3 right">
                  <Link to="/events-management" >
                    <IconButton aria-label="Mail">
                      <MoreVert className="white" fontSize="small"/> 
                    </IconButton>
                  </Link>
                  </div>
                </div>
                <div className="card-section no-padding">
                <Events/>
                </div>
              </div>
            </div>

            <div className="large-12 medium-12 small-12 cell margin-distributed">
              <h3>PROJECTS</h3>
              <Projects/>
            </div>

          </div>
        </div>
      </div>
    )
  }

}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  pageAttributes: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps,{pageAttributes})(Dashboard);