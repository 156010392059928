import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleDrawerL, toggleDrawerR } from '../../actions/drawerActions';

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton
} from '@material-ui/core';
 
import {
  MoreVert
} from '@material-ui/icons';

// # COMPONENTS

import CloseMorph from '../CloseMorph/close-morph';

const drawerControl =  {
  width: "calc(100% - 320px)",
}

class Header extends Component {

  openLeftDrawer() {
    this.props.toggleDrawerL(this.props.drawers.left.open);
  }

  openRightDrawer() {
    this.props.toggleDrawerR(this.props.drawers.right.open);
  }
  render() { 

    const { isAuthenticated, page } = this.props.auth;

    const header = (
      <header className={this.props.drawers.right.open ? drawerControl:"admin-header no-padding large-8 medium-8 small-12 cell"}>
        <AppBar position="static" color="default">
          <Toolbar>
            <IconButton fontSize="small"
              onClick={this.openLeftDrawer.bind(this)}
              style={{ marginLeft: -12, marginRight: 20 }}
              color="primary"
              aria-label="Menu">
              <CloseMorph />
            </IconButton>
            <Typography variant="h6" color="primary" style={{ flexGrow: 1 }}>
              {page.name}
            </Typography>
            <IconButton aria-label="More" onClick={this.openRightDrawer.bind(this)}>
              <MoreVert className="blue" fontSize="small" />
            </IconButton>
          </Toolbar>
        </AppBar>
      </header>
    );

    return (
      <div>
        {isAuthenticated ? header : ''}
      </div>
    )
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  toggleDrawerL: PropTypes.func.isRequired,
  toggleDrawerR: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  drawers: state.drawers
});


export default connect(mapStateToProps, { toggleDrawerL, toggleDrawerR })(Header);