import React, { Component } from 'react';
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';

const apiOptions = {
  ...connectorNodeV1.apiOptions,
  apiRoot: '/filemanager', // Or you local Server Node V1 installation. http://localhost:3001
  "capabilities": {
    "canListChildren": true,
    "canAddChildren": true,
    "canRemoveChildren": true,
    "canDelete": true,
    "canRename": false,
    "canCopy": false,
    "canEdit": false,
    "canDownload": false
}
}

class FileManagement extends Component{
  render(){

    const fileManager =  (
      <div className="margin-top-2x" style={{ height: '480px' }}>
         <FileManager>
           <FileNavigator
             id="filemanager-1"
             api={connectorNodeV1.api}
             apiOptions={apiOptions}
             initialResourceId={'Lw'}
             capabilities={connectorNodeV1.capabilities}
             listViewLayout={connectorNodeV1.listViewLayout}
             viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
           />
         </FileManager>
       </div>
     );

    return(
      <div>
        {fileManager}
      </div>
    )
  }
}

export default FileManagement;