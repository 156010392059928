import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// ACTIONS
import { getNotifications, deleteNotification } from '../../../actions/notificationsActions';

import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import {
  Delete
} from '@material-ui/icons';

// COMPONENT UTILS
import Spinner from '../../../assets/images/ui/spinner.svg';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading: false
    }
  }

  componentDidMount() {
    this.props.getNotifications();
  };// COMPONENT DID MOUNT


deleteItem(itemID) {
  this.props.deleteNotification(itemID);
  setTimeout(()=>{
    this.props.getNotifications();
  }, 1000);
}// DELETE


  render() {

    const { loading, notificationsList, itemsCount } = this.props.notifications;
    const contentLoading = (
      <div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>
    );

    let contentRender;
    if(notificationsList.length > 0){
      contentRender = (
        <List>
          {notificationsList.map((i) =>
            <ListItem key={i._id} className="white-bg margin-bottom round-medium">
              <ListItemText 
              primary={
                <Typography variant="subtitle2">
                  {i.name}
                </Typography>
              } 
              secondary={
              <React.Fragment>
                <Typography component="span"  variant="caption" color="textPrimary">
                  {i.user ? i.user.name +" "+ i.user.surname : "No User" }
                </Typography>
                {i.date}
              </React.Fragment>
              } />
              <ListItemSecondaryAction>
                <IconButton aria-label="Delete">
                  <Delete onClick={() => this.deleteItem(i._id)} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      )
    }else{
      contentRender = (
        <div className="white">No Notifications</div>
      )
    }

    
     

    return (
      <div>
        {loading ? contentLoading : contentRender}
      </div>
    )
  }
}

Notifications.proptypes = {
  getNotifications: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  auth: PropTypes.object.isrequired,
  notifications: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notifications: state.notifications
});

export default connect(mapStateToProps, {getNotifications, deleteNotification})(Notifications);