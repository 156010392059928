import React, { Component } from 'react';

class AdminUsers extends Component{
  render(){
    return(
      <div>Admin Users</div>  
    )
  }
}

export default AdminUsers;