import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions/authActions';
import {Link} from "react-router-dom";
//import store from '../../store';
import './menu.scss';

// ICONS IMPORT
import options from '../../images/ui/options.svg';
import clients from '../../images/ui/clients.svg';
import services from '../../images/ui/services.svg';
import slider from '../../images/ui/slider.svg';
import invoice from '../../images/ui/invoice.svg';
import payments from '../../images/ui/payments.svg';
import chip from '../../images/ui/chip.svg';

import efxLogo from '../../images/EFX-2015.svg';


class Menu extends Component {
  // constructor(props){
  //   super(props);
  //   this.state = {
  //     user: ''
  //   };
  // }//constructor

  // componentDidMount(){
  //   this.setState({
  //     user: store.getState().auth.user
  //   });
  // }

  onLogoutClick(e){
    e.preventDefault();
    this.props.logoutUser();
  }//logs out the user


  render(){

    const {isAuthenticated, user} = this.props.auth;

    const authLinks = (
      <ul className="grid-x grid-margin-x menu vertical">
       <li className="cell large-12 medium-12 small-12 margin-bottom">
        <Link  className="button expanded small white-bg gray_3" to="/settings">System Settings</Link>
       </li>
       <li className="cell large-12 medium-12 small-12 margin-bottom">
        <Link className="button expanded small white-bg gray_3" to="/admin-users">Site Admins</Link>
       </li>
      </ul>
    );// build more of this method

    return(
      <div>
        <div className="cell hide-for-small user-info padding-distributed medium-center small-center white-bg logo-banner">
          <Link to="/adminx"><img className="user-image" src={efxLogo} width="50" alt="efx logo"/></Link>
          <span className="user-name show-for-large">
            COMPANY NAME
          </span>
        </div>
        <div className="cell center f-15 padding-distributed white">{user.email}</div>
        <div className="padding-all" id="sideMenu">
          <ul className="grid-x grid-margin-x menu icons icon-top f-14 user-menu">
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/dashboard">
                <img src={options} alt="option-icon"/>
                <span>STATUS</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/clients-management">
                <img src={clients} alt="clients-icon"/>
                <span>CLIENTS</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/services-management">
                <img src={services} alt="services-icon"/>
                <span>SERVICES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/pages-management">
                <img src={slider} alt="pages-icon"/>
                <span>PAGES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/projects-management">
                <img src={slider} alt="projects-icon"/>
                <span>PROJECTS</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/slider-management">
                <img src={slider} alt="slider-icon"/>
                <span>SLIDER</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/invoices-management">
                <img src={invoice} alt="invoices-management"/>
                <span>INVOICES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/payments">
                <img src={payments} alt="payments-icon"/>
                <span>PAYMENTS</span>
              </Link>
              </li>
              <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/microcontrollers">
                <img src={chip} alt="controller-icon"/>
                <span>MCTR</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/files-management">
                <img src={chip} alt="controller-icon"/>
                <span>FILES</span>
              </Link>
            </li>
            <li className="cell large-6 medium-6 small-6 margin-bottom center">
              <Link className="white-bg" to="/events-management">
                <img src={chip} alt="calendar-icon"/>
                <span>EVENTS</span>
              </Link>
            </li>
          </ul>
          
          {isAuthenticated ? authLinks: ''}

          <ul className="grid-x grid-margin-x menu vertical">
            <li className="cell large-12 medium-12 small-12 margin-bottom">
              <Link to="/admin-login" className="button expanded small white-bg gray_3">Admin Login</Link>
            </li>
            <li className="cell large-12 medium-12 small-12 margin-bottom">
              <Link className="button expanded small white-bg red" to="" onClick={this.onLogoutClick.bind(this)}>{user.name} LOG OUT</Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}


const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {logoutUser})(Menu);