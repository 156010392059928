import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {modules, formats} from '../../TextEditor/TextEditorConfig';

//MATERIAL UI 
import {Button, 
  AppBar,
  Toolbar,
  Dialog, 
  Slide, 
  DialogContent, 
  DialogActions,
  Typography} from '@material-ui/core';

class AddItemFullDialog extends Component{

  constructor(props){
    super(props);
    this.state = {
      submitResponse: '',
      validForm: false, 
      submitting: false,
      isVerified: false,
      description: ''
    }
    this.inputChange = this.inputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ description: e });
    //console.log(this.state.description); check if data is generating
  }
  
  inputChange(e){
    this.setState({ [e.target.name]: e.target.value });
  }
  
  formSubmit(e){
    e.preventDefault();
  
      setTimeout(() => {
        this.setState({
          submitting: true
        });
      }, 800);  
    
      const form = document.getElementById("createPages"); // or document.forms
      const formData = new FormData(form);
    
      const values = {};
      for (let [key, value] of formData.entries()) {
        if (values[key]) {
          if ( ! (values[key] instanceof Array) ) {
            values[key] = new Array(values[key]);
          }
          values[key].push(value);
        } else {
          values[key] = value;
        }
      }// RECYCLABLE PROCESS TO GET VALUES FROM FIELDS IN REACT NOT SUITABLE FOR IMAGE UPLOAD

      values.description = this.state.description;

      console.log("form Values", values);
    
      axios({
          method: 'post',
          url: '/pages',
          headers: {
           'Authorization' : "Bearer " + localStorage.getItem('auth')
           },
          data: values
        }).then(response => {
          console.log(response);
          if(response.status === 201){
            this.setState({
              submitResponse: "Pages Created",
              validForm: true,
              submitting:false
            });
            setTimeout(()=>{
              this.props.handleClose();
              this.props.getPages();
              this.setState({ 
                description: '', 
                submitResponse: '' 
              });

            }, 1000);//
          }
        })
        .catch(error => {
          console.log(error);
    
          setTimeout(()=>{
            this.setState({
              submitResponse: "Please review the form details before sending",
              validForm: false,
              submitting: false
            });
          },1000);
        });
    
      //console.log("contact form: " + JSON.stringify(values));
  }

  clickSubmit(){
    const form = document.getElementById("createPages"); // or document.forms
    form.dispatchEvent(new Event('submit'));
  }

  render(){
    
    const {validForm, submitResponse, description} = this.state;
    const {dialogIsOpen, handleClose} = this.props;

    let formConfirmation = <div className={`cell large-12 strong ${validForm ? 'green':'red'}`} dangerouslySetInnerHTML={{ __html: this.state.submitResponse }}></div>
    let sending;

    if(this.state.submitting){
      sending = "SENDING";
    }else{
      sending = "ADD PAGE";//change the send button
    }


    return(
      <Dialog fullScreen open={this.props.dialogIsOpen} onClose={handleClose}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" color="inherit" style={{flexGrow: 1}}>
              ADD CLIENT
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              CLOSE
            </Button>
          </Toolbar>
        </AppBar>
      <Slide direction="up" in={dialogIsOpen} mountOnEnter unmountOnExit>
      <DialogContent className="margin-top-3x">
          <div className="grid-container">
            <div className="grid-x grid-margin-x grid-padding-x">
              <div className="cell large-12 medium-12 margin-top-2x">
                <form data-abide noValidate id="createPages" onSubmit={this.formSubmit.bind(this)} encType="multipart/form-data" ref={el => this.form = el}>
                
                <label htmlFor="title">Page Title
                  <input type="text" name="title" onChange={this.inputChange} aria-errormessage="titleErr" required />
                  <span className="form-error" id="titleErr">
                      Yo, you had better fill this out, it's required.
                  </span>
                </label>

                <label htmlFor="description">Page Content</label>
                <ReactQuill 
                value={description} 
                placeholder="Enter your page Content" 
                onChange={this.handleChange}
                theme="snow"
                modules={modules}
                formats={formats}/>

                  {formConfirmation}
                </form>
              </div>
              </div>
          </div>

      </DialogContent>
      </Slide>
      <DialogActions>
        <Typography>
        {submitResponse}
        </Typography>
        <Button onClick={handleClose} color="secondary">
          CANCEL
        </Button>
        <Button onClick={()=> this.clickSubmit()} color="primary" autoFocus>
          {sending}
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
}

AddItemFullDialog.propTypes = {
  formSubmit: PropTypes.func
}

export default AddItemFullDialog;