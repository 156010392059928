import React, { Component } from 'react';

class EventsManagement extends Component{
  render(){
    return(
      <div>Events</div>  
    )
  }
}

export default EventsManagement;