import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';
import axios from 'axios';

// ACTIONS
import { pageAttributes } from '../../../actions/authActions';
import { getPages } from '../../../actions/pagesActions';

//UTIL COMPONENTS
import AddItemFullDialog from './addItemFullDialog';
import DeleteAlert from '../../Alerts/DeleteAlert';
import Spinner from '../../../assets/images/ui/spinner.svg';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  AppBar,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class PagesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAlertIsOpen: false,
      alertContent: "",
      alertHeader: "",
      alertData: null,
      alertRequestUrl: null,
      dialogIsOpen: false,
      copied: false
    }
    this.inputChange = this.inputChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }// CONSTRUCTOR

  componentDidMount() {
    this.props.getPages();
    const page = {
      name: "Pages"
    };// include theme changes in this object
    this.props.pageAttributes(page);
  }//COMPONENT DID MOUNT

  inputChange(e) {
    this.setState({ 
      search: e.target.value,
      clients: _.filter(this.state.clients, {name: this.state.search})
    });
    console.log(this.state.search);
  }

  handleClickOpen() {
    this.setState({ dialogIsOpen: true });
  };

  handleClose() {
    this.setState({ dialogIsOpen: false });
  };

  handleAlertClose() {
    this.setState({ confirmAlertIsOpen: false });
  };

  deleteItem(itemID) {
    console.log(itemID);

    axios({
      method: 'get',
      url: '/pages/' + itemID,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem('auth')
      }
    }).then(res => {
      console.log(res.data);

      let page = res.data

      this.setState({
        confirmAlertIsOpen: true,
        alertHeader: "Delete Page?",
        alertContent: page.title,
        alertData: res.data,
        alertRequestUrl: `/pages/${res.data._id}`
      });
    }).catch(err => {
      console.log(err);
    });//axios

  }// DELETE

  updateCopy() {
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  }


  render() {

    const theme = createMuiTheme({
      palette: {
        primary: {
          light: '#757ce8',
          main: '#3f50b5',
          dark: '#002884',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000'
        }
      },
      typography: {
        useNextVariants: true,
      }
    });

    const { dialogIsOpen, confirmAlertIsOpen, alertHeader, alertContent, alertRequestUrl, alertData, copied } = this.state;
    const { pagesData, loading } = this.props.pages;
    const list = Array.from(pagesData);// turn it into array

    const itemsCount = pagesData.length;

    let itemsList;

    if (pagesData) {
      itemsList = (
        <TableBody>
          {list.map((page) =>
            <TableRow key={page._id}>
              <TableCell component="th" scope="row"> {page.title}  </TableCell>
              <TableCell>{renderHTML(page.description.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "").split(" ").splice(0, 4).join(" "))}</TableCell>
              <TableCell>
                <Tooltip title={copied ? "Copied!" : "Copy ID"}>
                  <CopyToClipboard text={page._id}
                    onCopy={() => this.updateCopy()}>
                    <IconButton color="primary"><i className="fa fa-clipboard" style={{ fontSize: 15 }}></i></IconButton>
                  </CopyToClipboard>
                </Tooltip>
              </TableCell>
              <TableCell>
                <IconButton color="primary"><i className="fa fa-pen" style={{ fontSize: 15 }}></i></IconButton>
              </TableCell>
              <TableCell>
                <IconButton color="secondary" aria-label="Add Client" onClick={() => this.deleteItem(page._id)}>
                  <i className="fa fa-window-close" style={{ fontSize: 15 }}></i>
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      );//
    } else {
      itemsList = (
        <div> No Services Loaded</div>
      )
    }

    const contentLoading = (<div className="center padding-all"> <img src={Spinner} width="25" height="25" alt="loader" /> </div>);

    return (
      <MuiThemeProvider theme={theme}>
        <div className="grid-x grid-margin-x grid-padding-x">
          <div className="large-12 medium-12 small-12 cell margin-top-2x">
            <Paper>
              <AppBar position="static" color="default" className="padding-distributed">
                <div className="grid-x grid-margin-x grid-padding-x">
                  <div className="cell large-3 medium-4 small-4 white counter">
                    <Typography variant="h6">
                      {itemsCount} PAGES
                   </Typography>
                  </div>
                  <div className="cell large-6 medium-6 small-6">
                    <input type="text" name="search" className="search-MU round-small" placeholder="Search" onChange={this.inputChange} />
                  </div>
                  <div className="cell large-3 medium-2 small-2 right">
                    <Button color="primary" variant="contained" onClick={this.handleClickOpen}>
                      + PAGE
                     </Button>
                  </div>
                </div>
              </AppBar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>EDIT</TableCell>
                    <TableCell>DELETE</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? "" : itemsList}
              </Table>
            </Paper>
            {loading ? contentLoading : ""}
          </div>

          <AddItemFullDialog dialogIsOpen={dialogIsOpen} handleClose={() => this.handleClose()} getPages={() => this.props.getPages()} />
          <DeleteAlert confirmAlertIsOpen={confirmAlertIsOpen}
            content={alertContent}
            handleAlertClose={() => this.handleAlertClose()}
            alertHeader={alertHeader}
            alertData={alertData}
            alertRequestUrl={alertRequestUrl}
            getPages={() => this.props.getPages()}
          />

        </div>
      </MuiThemeProvider>
    )
  }
}

PagesManagement.propTypes = {
  auth: PropTypes.object.isRequired,
  pageAttributes: PropTypes.func.isRequired,
  getPages: PropTypes.func.isRequired,
  pages: PropTypes.object.isRequired,
  pagesData: PropTypes.object
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  clients: state.clients,
  pages: state.pages
});

export default connect(mapStateToProps, { getPages, pageAttributes })(PagesManagement);